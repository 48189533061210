@keyframes lane {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-50%, 0);
	}
}

@supports (transform: translate(calc(-50% + 0.625rem), 0)) {
	@keyframes lane {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(calc(-50% + 0.625rem), 0);
		}
	}
}

.marquee {
}

.marquee__wrapper {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	overflow: hidden;
}

@media (--mq-from-medium) {
	.marquee__wrapper {
		margin-top: var(--space-medium);
		margin-bottom: var(--space-medium);
	}
}

.marquee:not(.is-animating) .marquee__wrapper {
	overflow-x: scroll;
	overflow-y: hidden;
}

.marquee__lane {
	display: inline-block;
	white-space: nowrap;
}

.marquee.is-animating .marquee__lane {
	animation-iteration-count: infinite;
	animation-name: lane;
	animation-timing-function: linear;
}

.marquee__lane-wrapper {
	display: inline-block;
}

.marquee__message,
.marquee__message__text,
.marquee__message__author {
	display: inline-block;
}

.marquee__message__text,
.marquee__message__author {
	margin-bottom: 0 !important;
}

.marquee__message__text {
	color: var(--color-electric);
	font-size: 1rem;
	padding-left: 0.75rem;
	vertical-align: bottom;
}

@media (--mq-from-small) {
	.marquee__message__text {
		font-size: 1.125rem;
	}
}

@media (--mq-from-wide) {
	.marquee__message__text {
		font-size: 1.5rem;
	}
}

.marquee__message__author {
	color: var(--color-pitch);
	font-size: 0.875rem;
	vertical-align: bottom;
}

@media (--mq-from-small) {
	.marquee__message__author {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	.marquee__message__author {
		font-size: 1.125rem;
	}
}

.marquee__lane-wrapper:last-child .marquee__message:last-child .marquee__message__author {
	padding-right: 1.25rem;
}
