/*
 * text
 * ====
 * Purpose:
 * [1] Modify text alignment
 * [2] Fix when someone set wrapper to full-width
 */

.text {
	text-align: left;
}

/* [1] */
@media (--mq-from-small) {
	.text {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.text {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

.text--align-center {
	text-align: center; /* [1] */
}

.text--align-right {
	text-align: right; /* [1] */
}

.text--full-width {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}
