@media (--mq-up-to-medium) {
	.detail__body {
		margin-top: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.detail__body {
		display: flex;
		flex-shrink: 0;
	}
}

@media (--mq-up-to-medium) {
	.detail__content:not(:only-child) {
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.detail__content:not(:only-child) {
		margin-left: var(--space-medium);
		order: 1;
	}
}

@media (--mq-from-medium) {
	.detail__content,
	.detail__images {
		flex-basis: calc((100% - var(--space-medium)) / 2);
		flex-grow: 1;
	}
}

.detail__content__title {
	color: var(--color-stoplight);
	margin-bottom: 0 !important;
}

.detail__content__subtitle,
.detail__content__date-time {
	color: var(--color-stoplight);
}

.detail__keywords {
	margin-top: var(--space-medium);
}
