.doormat-locations {
	background-color: var(--color-pitch);
	color: var(--color-stoplight);
	justify-content: center;
	align-items: center;
	position: relative;
}

ul.doormat-locations__switch {
	font-size: 1rem;
	font-weight: var(--font-weight-bold);
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

ul.doormat-locations__switch li {
	list-style: none;
}

ul.doormat-locations__switch a {
	display: inline-block;
	text-transform: uppercase;
	transform: rotateX(180deg);
	min-width: 150px;
}

ul.doormat-locations__switch a:hover,
ul.doormat-locations__switch a:focus {
	color: var(--color-stoplight);
	text-decoration: underline;
	transform: rotate(0);
}

@media (min-width: 768px) {
	ul.doormat-locations__switch {
		font-size: 1.375rem;
	}
}

@media (min-width: 1680px) {
	ul.doormat-locations__switch {
		font-size: 1.875rem;
	}
}

.doormat-locations__logo {
	position: absolute;
	right: 0.625rem;
	bottom: 0.625rem;
	transform: rotate(180deg);
}

@media (min-width: 1380px) {
	.doormat-locations__logo {
		right: 1.25rem;
		bottom: 1.25rem;
	}
}

.doormat-locations__logo .icon {
	width: 78px;
	height: 20px;
}

@media (min-width: 768px) {
	.doormat-locations__logo .icon {
		width: 103px;
		height: 25px;
	}
}

@media (min-width: 1680px) {
	.doormat-locations__logo .icon {
		width: 148px;
		height: 35px;
	}
}
