.slideshow {
	display: block;
	overflow: hidden;
	padding-top: 100vh;
	position: relative;
}

.slideshow[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

.slideshow[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

.slideshow[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

/* -0.5px fixes rounding problem on retina displays */

.slideshow[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2) - 0.5px);
}

.slideshow[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3) - 0.5px);
}

.slideshow[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3) - 0.5px);
}

.slideshow[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4) - 0.5px);
}

.slideshow[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16) - 0.5px);
}

.slideshow[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21) - 0.5px);
}

.slideshow.slideshow--expanded {
	box-sizing: border-box;
	padding: 0 1.25rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.slideshow.slideshow--expanded {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (min-width: 1280px) {
	.slideshow.slideshow--expanded {
		padding-right: 12.5%;
		padding-left: 12.5%;
	}
}

@media (min-width: 1440px) {
	.slideshow.slideshow--expanded {
		padding-right: 15%;
		padding-left: 15%;
	}
}

@media (min-width: 120em) {
	.slideshow.slideshow--expanded {
		margin-right: auto;
		margin-left: auto;
		padding-right: 0;
		padding-left: 0;
		width: 1344px;
	}
}

.slideshow.slideshow--outlined {
	border: 1px solid;
	padding: calc(3rem - 2px);
}

@media (--mq-from-medium) {
	.slideshow.slideshow--outlined {
		padding: calc(4rem - 2px);
	}
}

@media (--mq-from-large) {
	.slideshow.slideshow--outlined {
		padding: calc(5rem - 2px);
	}
}

@media (--mq-from-small) {
	.action-panel--fill-height .action-panel__slideshow .slideshow {
		padding-top: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.slideshow__outline-wrapper {
	display: block;
	overflow: hidden;
	padding-top: 100vh;
	position: relative;
}

.slideshow[data-aspect-ratio="1:1"] .slideshow__outline-wrapper {
	padding-top: 100%;
}

.slideshow[data-aspect-ratio="1:2"] .slideshow__outline-wrapper {
	padding-top: 200%;
}

.slideshow[data-aspect-ratio="2:1"] .slideshow__outline-wrapper {
	padding-top: 50%;
}

.slideshow[data-aspect-ratio="2:3"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (3 / 2));
}

.slideshow[data-aspect-ratio="3:2"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (2 / 3));
}

.slideshow[data-aspect-ratio="3:4"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (4 / 3));
}

.slideshow[data-aspect-ratio="4:3"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (3 / 4));
}

.slideshow[data-aspect-ratio="16:9"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (9 / 16));
}

.slideshow[data-aspect-ratio="21:9"] .slideshow__outline-wrapper {
	padding-top: calc(100% * (9 / 21));
}

/**
 * Slideshow pane
 */
.slideshow--expanded .slideshow__pane {
	display: block;
	overflow: hidden;
	padding-top: 100vh;
	position: relative;
}

.slideshow--expanded[data-aspect-ratio="1:1"] .slideshow__pane {
	padding-top: 100%;
}

.slideshow--expanded[data-aspect-ratio="1:2"] .slideshow__pane {
	padding-top: 200%;
}

.slideshow--expanded[data-aspect-ratio="2:1"] .slideshow__pane {
	padding-top: 50%;
}

.slideshow--expanded[data-aspect-ratio="2:3"] .slideshow__pane {
	padding-top: calc(100% * (3 / 2));
}

.slideshow--expanded[data-aspect-ratio="3:2"] .slideshow__pane {
	padding-top: calc(100% * (2 / 3));
}

.slideshow--expanded[data-aspect-ratio="3:4"] .slideshow__pane {
	padding-top: calc(100% * (4 / 3));
}

.slideshow--expanded[data-aspect-ratio="4:3"] .slideshow__pane {
	padding-top: calc(100% * (3 / 4));
}

.slideshow--expanded[data-aspect-ratio="16:9"] .slideshow__pane {
	padding-top: calc(100% * (9 / 16));
}

.slideshow--expanded[data-aspect-ratio="21:9"] .slideshow__pane {
	padding-top: calc(100% * (9 / 21));
}

/**
 * Slideshow item
 */
.slideshow__item {
	display: block;
	margin: 0 !important;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: opacity 512ms linear;
	z-index: 0;
}

.slideshow__item.is-active {
	opacity: 1;
	z-index: 10;
}

/**
 * Slideshow item - asset (image or video)
 */
.slideshow__item__asset {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: auto;
	z-index: 0;
}

@supports (object-fit: cover) {
	.slideshow__item__asset {
		object-fit: cover;
		height: 100%;
	}
}

/**
 * Slideshow item - caption
 */
.slideshow__item__caption {
	display: none;
}

@media (--mq-from-small) {
	.slideshow__item__caption {
		color: white;
		display: block;
		position: absolute;
		bottom: 1.25rem;
		left: 1.25rem;
		z-index: 10;
	}
}

@media (--mq-from-medium) {
	.slideshow__item__caption {
		bottom: var(--space-medium);
		left: var(--space-medium);
	}
}

/**
 * Slideshow item - content
 */
.slideshow__item__content {
	display: block;
	position: absolute;
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;
	transform: translate(-50%, -50%);
}

.slideshow__item__content.slideshow__item__content--left-top {
	top: 25%;
	left: 25%;
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--left-top {
		top: 1.25rem;
		left: 1.25rem;
		transform: none;
	}
}

.slideshow__item__content.slideshow__item__content--left-center {
	top: 50%;
	left: 25%;
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--left-center {
		top: 50%;
		left: 1.25rem;
		transform: translate(0, -50%);
	}
}

.slideshow__item__content.slideshow__item__content--left-bottom {
	top: auto;
	bottom: 25%;
	left: 25%;
	transform: translate(-50%, 50%);
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--left-bottom {
		bottom: 1.25rem;
		left: 1.25rem;
		transform: none;
	}
}

.slideshow__item__content.slideshow__item__content--right-top {
	top: 25%;
	right: 25%;
	left: auto;
	transform: translate(50%, -50%);
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--right-top {
		top: 1.25rem;
		right: 1.25rem;
		transform: none;
	}
}

.slideshow__item__content.slideshow__item__content--right-center {
	top: 50%;
	right: 25%;
	left: auto;
	transform: translate(50%, -50%);
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--right-center {
		top: 50%;
		right: 1.25rem;
		transform: translate(0, -50%);
	}
}

.slideshow__item__content.slideshow__item__content--right-bottom {
	top: auto;
	right: 25%;
	bottom: 25%;
	left: auto;
	transform: translate(50%, 50%);
}

@media (--mq-up-to-xsmall) {
	.slideshow__item__content.slideshow__item__content--right-bottom {
		right: 1.25rem;
		bottom: 1.25rem;
		transform: none;
	}
}

.slideshow__item__content__text {
	text-align: center;
}

.slideshow__item__content__text p {
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	line-height: 0.88em;
}

@media (--mq-from-xsmall) {
	.slideshow__item__content__text p {
		font-size: 1.75rem;
	}
}

@media (--mq-from-small) {
	.slideshow__item__content__text p {
		font-size: 2rem;
	}
}

@media (--mq-from-medium) {
	.slideshow__item__content__text p {
		font-size: 2.25rem;
	}
}

@media (--mq-from-wide) {
	.slideshow__item__content__text p {
		font-size: 2.75rem;
	}
}

@media (--mq-from-large) {
	.slideshow__item__content__text p {
		font-size: 3rem;
	}
}

/**
 * Navigation
 */
.slideshow__next,
.slideshow__previous {
	border: 1px solid currentColor;
	box-sizing: border-box;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	width: 2rem;
	height: 2rem;
	z-index: 20;
}

@media (--mq-from-small) {
	.slideshow__next,
	.slideshow__previous {
		width: 3rem;
		height: 3rem;
	}
}

@media (--mq-from-medium) {
	.slideshow__next,
	.slideshow__previous {
		width: 4rem;
		height: 4rem;
	}
}

@media (--mq-from-large) {
	.slideshow__next,
	.slideshow__previous {
		width: 5rem;
		height: 5rem;
	}
}
/* arrow */
.slideshow__next::before,
.slideshow__previous::before {
	border-style: solid;
	border-width: 15px 20px;
	box-sizing: border-box;
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0.5);
	width: 0;
	height: 0;
}

.slideshow--outlined .slideshow__next,
.slideshow--outlined .slideshow__previous {
	transform: none;
}

@media (--mq-up-to-medium) {
	.slideshow--outlined .slideshow__next,
	.slideshow--outlined .slideshow__previous {
		width: 3rem;
		height: 3rem;
	}
}

.slideshow--outlined .slideshow__next::before,
.slideshow--outlined .slideshow__previous::before {
	content: none;
	display: none;
}

.slideshow__navigation-arrow {
	color: currentColor;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
	width: 25%;
	height: 25%;
}

.slideshow__navigation-arrow > polygon {
	fill: none;
	stroke: currentColor;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
}

.slideshow__previous .slideshow__navigation-arrow {
	transform: translate(-50%, -50%) rotate(180deg);
}

.slideshow__next {
	right: 1.25rem;
}

@media (--mq-from-medium) {
	.slideshow__next {
		right: var(--space-medium);
	}
}

.slideshow__next::before {
	border-color: transparent transparent transparent currentColor;
	border-width: 15px 0 15px 20px;
}

.slideshow--outlined .slideshow__next {
	top: auto;
	right: -1px;
	bottom: -1px;
}

.slideshow--expanded .slideshow__next {
	right: 0;
}

.slideshow__previous {
	left: 1.25rem;
}

@media (--mq-from-medium) {
	.slideshow__previous {
		left: var(--space-medium);
	}
}

.slideshow__previous::before {
	border-color: transparent currentColor transparent transparent;
	border-width: 15px 20px 15px 0;
}

.slideshow--outlined .slideshow__previous {
	top: -1px;
	bottom: auto;
	left: -1px;
}

.slideshow--expanded .slideshow__previous {
	left: 0;
}

.slideshow__call-to-action {
	font-size: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

@media (max-width: 1279px) {
	.slideshow__call-to-action {
		text-align: center;
		width: 100%;
	}
}

@media (min-width: 1280px) {
	.slideshow__call-to-action {
		box-sizing: border-box;
		padding: 10rem 1rem 0;
		position: absolute;
		right: 0;
		width: 12.5%;
	}
}

@media (min-width: 1440px) {
	.slideshow__call-to-action {
		font-size: 1.125rem;
		padding-right: 2rem;
		padding-left: 2rem;
		width: 15%;
	}
}

@media (min-width: 120em) {
	.slideshow__call-to-action {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
		width: calc((100% - 1344px) / 2);
	}
}

.slideshow__meta {
	box-sizing: border-box;
	display: flex;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	z-index: 20;
}

@media (--mq-from-small) {
	.slideshow__meta {
		padding-right: 3.25rem;
		padding-left: 3.25rem;
	}
}

@media (--mq-from-medium) {
	.slideshow__meta {
		padding-right: 6.5rem;
		padding-left: 5.5rem;
	}
}

@media (--mq-from-wide) {
	.slideshow__meta {
		padding-left: 4.625rem;
	}
}

@media (min-width: 1440px) {
	.slideshow__meta {
		padding-right: 9.75rem;
	}
}

.slideshow__meta__captions,
.slideshow__meta__pagination {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-small) {
	.slideshow__meta__captions,
	.slideshow__meta__pagination {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
	}
}

@media (--mq-from-wide) {
	.slideshow__meta__captions,
	.slideshow__meta__pagination {
		padding-top: 1.9375rem;
		padding-bottom: 1.9375rem;
	}
}

.slideshow__meta__captions {
	box-sizing: border-box;
	display: flex;
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	padding-right: 0.5rem;
	align-items: stretch;
}

@media (--mq-from-small) {
	.slideshow__meta__captions {
		padding-right: 1rem;
	}
}

@media (--mq-from-wide) {
	.slideshow__meta__captions {
		padding-right: 2rem;
	}
}

.slideshow__meta__captions__item {
	color: #b3b3b3;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	font-size: 0.875rem;
	opacity: 0;
	transition: none;
}

.slideshow__meta__captions__item.is-active {
	opacity: 1;
	order: -1;
	transition: opacity 320ms 96ms linear;
}

.slideshow__meta__pagination {
	box-sizing: border-box;
	display: flex;
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: flex-end;
}

.slideshow__pagination__item {
	appearance: none;
	background: white;
	border: 1px solid black;
	border-radius: 50%;
	box-shadow: none;
	display: block;
	flex-basis: 0.625rem;
	flex-grow: 0;
	flex-shrink: 0;
	margin-left: 0.3125rem;
	outline: none;
	padding: 0.1875rem 0 0;
	width: 0.625rem;
	height: 0.625rem;
}

.slideshow__pagination__item:hover {
	cursor: pointer;
}

.slideshow__pagination__item.is-active {
	background: black;
}
