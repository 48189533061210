.space {
	margin-bottom: var(--space-x-small);
}

.space__link {
	color: var(--color-black);
	text-decoration: none;
	transition: color 96ms linear;
}

.space__link:focus,
.space__link:hover {
	color: var(--color-electric);
}

.space__header {
	background-color: var(--color-flamingo);
	padding: 25px;
}

.space__header__wrapper {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.space__title {
	font-weight: var(--font-weight-bold);
	margin-bottom: 12px !important;
}

.space__data {
	display: block;
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.13;
	text-transform: lowercase;
}

@media (--mq-from-small) {
	.space__data {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.space__data {
		font-size: 2rem;
	}
}

.space__content {
	display: flex;
	align-items: stretch;
}

.space__content__image {
	flex-grow: 1;
}

.space__content__cta {
	display: flex;
	flex-direction: column;
	width: 72px;
}

.space__cta-text-wrapper {
	background-color: var(--color-flamingo);
	flex-grow: 1;
	position: relative;
}

.space__cta-text {
	opacity: 0;
	position: absolute;
	text-transform: uppercase;
	bottom: 25px;
	left: 50%;
	transform: translateY(50%) rotate(-90deg) translateZ(0);
	transform-origin: left center;
	transition:
		transform var(--time-medium) var(--ease-out-quad),
		opacity var(--time-small) linear var(--time-x-small);
}

.space__link:focus .space__cta-text,
.space__link:hover .space__cta-text {
	opacity: 1;
	transform: rotate(-90deg) translateZ(0);
}

.space__cta-triangle-wrapper {
	position: relative;
	height: 55px;
}

.space__cta-triangle {
	color: currentColor;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform-origin: center center;
	width: 25%;
	height: 25%;
}

.space__cta-triangle > polygon {
	fill: none;
	stroke: currentColor;
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 2px;
}
