@media (--mq-from-small) {
	.columns {
		display: flex;
		align-items: center;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.columns {
		flex-wrap: wrap;
	}
}

.columns.columns--multi-image {
	display: flex;
	align-items: center;
}

@media (--mq-up-to-medium) {
	.columns.columns--multi-image {
		flex-wrap: wrap;
	}
}

@media (--mq-from-small) {
	.columns.columns--stretch {
		align-items: stretch;
	}
}

@media (--mq-from-small) {
	.columns.columns--align-top {
		align-items: flex-start;
	}
}

.columns__item {
	flex-grow: 1;
}

@media (--mq-up-to-medium) {
	.columns__item:not(.columns__item--multi-image) + .columns__item:not(.columns__item--multi-image),
	.columns:not(.columns--multi-image) .columns__item + .columns__item {
		margin-top: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.columns__item {
		flex-basis: 20%;
	}

	.columns__item + .columns__item {
		margin-left: var(--space-medium);
	}
}

@media (--mq-up-to-medium) {
	.columns__item--multi-image {
		flex-basis: 45%;
		margin-top: 1.25rem;
		order: 4;
		max-width: calc((100% - 1.25rem) / 2);
	}
}

@media (--mq-up-to-medium) {
	figure.columns__item--multi-image:nth-of-type(even) {
		margin-left: 1.25rem;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.columns__item--embed,
	.columns__item--text {
		padding-right: calc((100% - 30rem) / 2);
		padding-left: calc((100% - 30rem) / 2);
	}
}
