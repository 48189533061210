.yust-logo {
	color: var(--color-pitch);
	display: inline-block;
	width: 80px;
}

@media (min-width: 375px) {
	.yust-logo {
		width: 104px;
	}
}

@media (--mq-from-wide) {
	.yust-logo {
		width: 152px;
	}
}

@media (--mq-from-large) {
	.yust-logo {
		width: 200px;
	}
}

.yust-logo .icon {
	display: block;
	stroke-miterlimit: 10;
	width: 80px;
	height: 20px;
}

@media (min-width: 375px) {
	.yust-logo .icon {
		width: 104px;
		height: 26px;
	}
}

@media (--mq-from-wide) {
	.yust-logo .icon {
		width: 152px;
		height: 38px;
	}
}

@media (--mq-from-large) {
	.yust-logo .icon {
		width: 200px;
		height: 50px;
	}
}

.yust-logo.yust-logo--outline .icon {
	fill: none;
}

.yust-logo__slogan {
	display: inline-block;
	font-size: 0.625rem;
	line-height: 1.5em;
	margin-top: 8px;
	margin-left: 65%;
	text-align: center;
	text-transform: uppercase;
	transform: translate(-50%, 0);
	width: 100%;
}

@media (--mq-from-wide) {
	.yust-logo__slogan {
		font-size: 0.875rem;
		margin-top: 25px;
		width: 75%;
	}
}
