.filter-link {
	color: inherit;
	font-size: 0.875rem; /* 14px */
	font-weight: 400;
	line-height: 1.125rem;
	padding-left: 1.5rem;
	position: relative;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.filter-link {
		font-size: 1rem;
		line-height: 1.3125rem; /* 131.25% */
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.filter-link {
		font-size: 1.125rem;
		line-height: 1.5625rem; /* 138.889% */
	}
}

.filter-link[data-active="true"] {
	font-weight: 700;
	text-decoration: none;
}

.filter-link::before {
	border: 2px solid var(--color-white);
	content: "";
	display: block;
	position: absolute;
	top: 0.0625rem;
	left: 0;
	width: 0.625rem;
	height: 0.625rem;
}

.filter-link[data-active="true"]::after {
	background-color: var(--color-white);
	content: "";
	display: block;
	position: absolute;
	top: 0.3125rem;
	left: 0.25rem;
	width: 0.375rem;
	height: 0.375rem;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.filter-link::before {
		top: 0.1875rem;
	}

	.filter-link[data-active="true"]::after {
		top: 0.4375rem;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.filter-link::before {
		top: 0.25rem;
	}

	.filter-link[data-active="true"]::after {
		top: 0.5rem;
	}
}
