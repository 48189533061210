@media (--mq-from-small) {
	.usps {
		border-top: 1px solid currentColor;
		display: flex;
	}
}

@media (min-width: 110em) {
	.usps {
		padding-right: calc((100% - 98rem) / 2);
		padding-left: calc((100% - 98rem) / 2);
	}
}

.usps__item {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	text-align: center;
}

@media (--mq-up-to-small) {
	.usps__item {
		border-top: 1px solid currentColor;
	}
}

@media (--mq-from-small) {
	.usps__item {
		flex-basis: 25%;
		flex-grow: 1;
		padding-right: 1.25rem;
		padding-left: 1.25rem;
		position: relative;
	}

	.usps__item:not(:last-child)::before {
		background-color: currentColor;
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 50px;
	}
}

@media (--mq-from-medium) {
	.usps__item {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}
}

.usps__link {
	color: currentColor;
	display: inline-block;
	text-decoration: underline;
}

.usps__link:hover {
	text-decoration: none;
}

@media (--mq-up-to-small) {
	.usps__title {
		margin-bottom: 0 !important;
	}
}

@media (--mq-from-small) {
	.usps__title {
		margin-bottom: 0.25rem !important;
	}
}
