/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.site-header {
	background-color: var(--color-white);
}

.site-header-primary,
.site-header-secondary {
	background-color: var(--color-white);
	display: none;
}

@media (min-width: 1024px) {
	.site-header-primary,
	.site-header-secondary {
		display: block;
	}
}

.site-header-primary {
	position: sticky;
	top: 0;
	z-index: var(--z-index-site-header-primary);
}

.site-header-secondary {
	position: relative;
	z-index: var(--z-index-site-header-secondary);
}

.site-header__wrapper {
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-pitch);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

@media (min-width: 1024px) {
	.site-header__main-navigation {
		display: flex;
	}
}

.site-header__main-navigation__link,
.site-header__meta-navigation__link {
	text-decoration: none;
}

.site-header__main-navigation__link:hover,
.site-header__meta-navigation__link:hover {
	color: var(--color-darkgrey);
}

.site-header__main-navigation__link.is-active,
.site-header__meta-navigation__link.is-active {
	color: var(--color-darkgrey);
}

@media (min-width: 1024px) {
	.site-header__main-navigation__link {
		display: block;
		padding: 0.9375rem;
		position: relative;
	}

	.site-header__main-navigation__link::after {
		border-right: 1px solid var(--color-pitch);
		content: "";
		display: block;
		position: absolute;
		top: 0.9375rem;
		right: 0;
		bottom: 0;
	}
}

@media (min-width: 1380px) {
	.site-header__main-navigation__link {
		padding: 1.25rem;
	}

	.site-header__main-navigation__link::after {
		top: 1.25rem;
	}
}

.site-header__main-navigation__list,
.site-header__meta-navigation__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (min-width: 1024px) {
	.site-header__main-navigation__list,
	.site-header__meta-navigation__list {
		display: flex;
	}
}

.site-header__main-navigation__list {
	font-weight: var(--font-weight-bold);
}

@media (max-width: 1023px) {
	.site-header__main-navigation__list {
		font-size: 1.25rem;
	}
}

.site-header__main-navigation__list__item .site-header__main-navigation__link.is-current {
	color: var(--color-stoplight);
}

@media (min-width: 1024px) {
	/* prevent panel from closing on transition */
	.site-header__main-navigation__list__item:hover::after,
	.site-header__location-switch:hover::after {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		bottom: -5px;
		left: 0;
		height: 10px;
	}

	.site-header__main-navigation__list__item:hover .site-header__sub-navigation,
	.site-header__location-switch:hover .site-header__sub-navigation {
		pointer-events: auto;
		transform: translateY(0);
	}

	.site-header__main-navigation__list__item:hover .site-header__sub-navigation__box {
		opacity: 1;
	}
}

/* sub menus */
.site-header__sub-navigation {
	background-color: var(--color-white);
	border-top: 1px solid var(--color-pitch);
	display: flex;
	padding: 0.9375rem 0.46875rem;
	pointer-events: none;
	position: absolute;
	top: 100%;
	right: 0;
	left: 0;
	transform: translateY(-100%);
	transition: transform 256ms ease-out;
	z-index: -1;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation {
		padding: 1.25rem 0.625rem;
	}
}

.site-header__sub-navigation__title,
.site-header__sub-locations-menu__title {
	font-size: 1.875rem;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation__title,
	.site-header__sub-locations-menu__title {
		font-size: 2.25rem;
	}
}

.site-header__sub-navigation__menu__list {
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	list-style: none;
	margin: 0;
	padding: 0;
	text-transform: uppercase;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation__menu__list {
		font-size: 1.125rem;
	}
}

.site-header__sub-navigation__menu__link {
	color: var(--color-stoplight);
}

.site-header__sub-navigation__menu__link.is-current {
	color: var(--color-electric);
}

.site-header__sub-navigation__menu__link:hover,
.site-header__sub-navigation__menu__link:focus {
	color: var(--color-electric);
	text-decoration: underline;
}

/* sub navigation blocks */
.site-header__sub-navigation__block,
.site-header__sub-locations-menu__block {
	flex-basis: 33.33%;
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
	max-width: 33.33%;
}

.site-header__sub-navigation__block::before {
	content: "";
	display: block;
	padding-top: 62%;
}

.site-header__sub-locations-menu__block {
	min-height: 300px;
}

.site-header__sub-navigation__block__inner,
.site-header__sub-locations-menu__block__inner {
	margin-right: 0.46875rem;
	margin-left: 0.46875rem;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation__block__inner,
	.site-header__sub-locations-menu__block__inner {
		margin-right: 0.625rem;
		margin-left: 0.625rem;
	}
}

.site-header__sub-navigation__block__inner {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: space-between;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.site-header__sub-locations-menu__block__inner {
	height: 100%;
}

.site-header__sub-navigation__box {
	background-color: var(--color-pitch);
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	opacity: 0;
	padding: 1.25rem;
	justify-content: center;
	align-items: center;
	position: relative;
	text-decoration: none;
	height: 100%;
}

.site-header__sub-navigation__box__text,
.site-header__sub-locations-menu__info__text {
	font-weight: var(--font-weight-regular);
	position: relative;
	text-align: center;
}

.site-header__sub-navigation__box__text h4,
.site-header__sub-locations-menu__info__text h4 {
	font-size: 1.75rem;
	margin-top: 0;
	margin-bottom: 1.25rem;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation__box__text h4,
	.site-header__sub-locations-menu__info__text h4 {
		font-size: 2.25rem;
	}
}

.site-header__sub-navigation__box__text p,
.site-header__sub-locations-menu__info__text p {
	margin: 0 auto;
}

@media (min-width: 1380px) {
	.site-header__sub-navigation__box__text,
	.site-header__sub-locations-menu__info__text {
		width: 85%;
	}
}

.site-header__sub-navigation__box__text h4 {
	text-transform: uppercase;
}

.site-header__sub-navigation__box__image,
.site-header__sub-locations-menu__info__image {
	object-fit: cover;
	opacity: 0.9;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.site-header__sub-locations-menu__block__info {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.site-header__sub-locations-menu__text {
	font-size: 1rem;
	width: 85%;
}

@media (min-width: 1380px) {
	.site-header__sub-locations-menu__text {
		width: 77%;
	}
}

/* navigation list */
@media (max-width: 1023px) {
	.site-header__meta-navigation__list {
		font-size: 1rem;
	}
}

@media (min-width: 1024px) {
	.site-header__meta-navigation__list {
		border-right: 1px solid var(--color-pitch);
		margin-top: 0.9375rem;
		padding-right: 0.46875rem;
		padding-bottom: 0.9375rem;
	}
}

@media (min-width: 1380px) {
	.site-header__meta-navigation__list {
		margin-top: 1.25rem;
		padding-right: 0.625rem;
		padding-bottom: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.site-header__meta-navigation__list__item {
		margin-right: 0.625rem;
		margin-left: 0.625rem;
	}
}

@media (max-width: 1023px) {
	.site-header__main-navigation__list__item {
		padding-top: 0.65rem;
	}

	.site-header__main-navigation__list__item:first-child {
		padding-top: 1.25rem;
	}
}

@media (max-width: 1023px) {
	.site-header__meta-navigation__list__item {
		padding-top: 0.65rem;
	}

	.site-header__meta-navigation__list__item:first-child {
		padding-top: 0.85rem;
	}
}

.site-header__meta-navigation__link.menu-item--has-star::after {
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PGcgZmlsbD0iIzAwMDAwMCI+PHBvbHlnb24gcG9pbnRzPSIyMCAwIDIzLjgzIDEwLjc2IDM0LjE0IDUuODYgMjkuMjQgMTYuMTcgNDAgMjAgMjkuMjQgMjMuODMgMzQuMTQgMzQuMTQgMjMuODMgMjkuMjQgMjAgNDAgMTYuMTcgMjkuMjQgNS44NiAzNC4xNCAxMC43NiAyMy44MyAwIDIwIDEwLjc2IDE2LjE3IDUuODYgNS44NiAxNi4xNyAxMC43NiAyMCAwIi8+PC9nPjwvc3ZnPg==");
	display: inline-block;
	margin-top: -3px;
	margin-left: 2px;
	vertical-align: middle;
	width: 0.875rem;
	height: 0.875rem;
}

.site-header__meta-navigation__link.menu-item--has-star.is-active::after,
.site-header__meta-navigation__link.menu-item--has-star:hover::after {
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PGcgZmlsbD0iIzkzOTM5MyI+PHBvbHlnb24gcG9pbnRzPSIyMCAwIDIzLjgzIDEwLjc2IDM0LjE0IDUuODYgMjkuMjQgMTYuMTcgNDAgMjAgMjkuMjQgMjMuODMgMzQuMTQgMzQuMTQgMjMuODMgMjkuMjQgMjAgNDAgMTYuMTcgMjkuMjQgNS44NiAzNC4xNCAxMC43NiAyMy44MyAwIDIwIDEwLjc2IDE2LjE3IDUuODYgNS44NiAxNi4xNyAxMC43NiAyMCAwIi8+PC9nPjwvc3ZnPg==");
}

.site-header__book-now-button {
	appearance: none;
	background-color: var(--color-stoplight);
	border: none;
	border-radius: 30px;
	color: var(--color-white);
	cursor: pointer;
	display: inline-block;
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	padding: 12px 24px 9px;
	text-decoration: none;
	text-transform: uppercase;
}

@media (min-width: 1024px) and (max-width: 1379px) {
	.site-header__book-now-button {
		font-size: 1rem;
	}
}

.site-header-secondary__brand,
.site-header-secondary__menus {
	display: flex;
	align-items: center;
}

.site-header-secondary__menus {
	font-size: 0.875rem;
}

.site-header__logo {
	margin-right: 0.9375rem;
}

.site-header__logo .icon-yust-logo {
	display: block;
	width: 5.375rem;
	height: 1.25rem;
}

@media (min-width: 1024px) {
	.site-header__logo {
		margin: 0 0.625rem 0 0.9375rem;
	}
}

@media (min-width: 1380px) {
	.site-header__logo {
		margin: 0 0.625rem 0 1.25rem;
	}

	.site-header__logo .icon-yust-logo {
		width: 6.75rem;
		height: 1.5625rem;
	}
}

.site-header__location-switch {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.site-header__location-switch .icon {
	margin-top: 1px;
	margin-left: 4px;
	transform: rotate(90deg);
	transition: transform 256ms ease-out;
	width: 0.7rem;
	height: 0.7rem;
}

@media (min-width: 1024px) {
	.site-header__location-switch {
		padding: 0.9375rem 0;
		min-width: 100%;
	}
}

@media (min-width: 1380px) {
	.site-header__location-switch {
		padding: 1.25rem 0;
	}
}

.site-header__location-switch__trigger {
	cursor: pointer;
	font-size: 1rem;
}

@media (min-width: 1024px) {
	.site-header__location-switch__trigger {
		font-size: 0.875rem;
		text-transform: uppercase;
	}
}

.site-header__language-switch {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

.site-header__language-switch .language-menu__list {
	right: 0;
	left: auto;
	transform: none;
}

@media (max-width: 1023px) {
	.site-header__language-switch {
		font-size: 1rem;
	}
}

@media (min-width: 1024px) {
	.site-header__language-switch {
		padding: 0.9375rem;
	}
}

@media (min-width: 1380px) {
	.site-header__language-switch {
		padding: 1.25rem;
	}
}

.site-header-small {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 0;
	z-index: var(--z-index-site-header-small);
}

@media (min-width: 1024px) {
	.site-header-small {
		display: none;
	}
}

.site-header-small__brand {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.site-header-small__current-location {
	font-size: 0.875rem;
	text-transform: uppercase;
}

.site-header-small__top__inner,
.site-header-small__switches {
	border-bottom: 1px solid var(--color-pitch);
}

.site-header-small__top {
	background-color: var(--color-white);
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	position: relative;
	z-index: 1;
}

.site-header-small__top__inner {
	background-color: var(--color-white);
	display: flex;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 1;
}

.site-header-small__main {
	background-color: var(--color-white);
	overflow-x: hidden;
	overflow-y: auto;
	overscroll-behavior: contain;
	padding-right: 1.25rem;
	padding-bottom: 1.25rem;
	padding-left: 1.25rem;
	scroll-behavior: smooth;
	transform: translateY(-100%);
	transition: transform 325ms ease-out;
	height: calc(100vh - 140px);
}

.site-header-small__menus {
	padding-bottom: 1.25rem;
}

.site-header-small__bottom {
	background-color: var(--color-white);
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateY(100%);
	transition: transform 256ms ease-out;
	z-index: var(--z-index-site-header-small-bottom);
}

.site-header-small__book-button {
	border-top: 1px solid var(--color-pitch);
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	text-align: center;
}

.site-header-small__switches {
	position: relative;
}

.site-header-small__switches .site-header__language-switch {
	position: absolute;
	top: 0;
	right: 0;
}

.site-header-small__menu-toggle {
	cursor: pointer;
	display: block;
	position: relative;
	text-indent: -9999rem;
	transition: transform 256ms ease-in-out;
	width: 25px;
	height: 20px;
}

.site-header-small__menu-toggle::before,
.site-header-small__menu-toggle::after {
	background-color: var(--color-white);
	border: 2px solid var(--color-pitch);
	content: "";
	display: block;
	position: absolute;
	right: 0;
	left: 0;
	transition: transform 256ms ease-in-out;
	height: 4px;
}

.site-header-small__menu-toggle::before {
	top: 0;
}

.site-header-small__menu-toggle::after {
	bottom: 0;
}

.site-header__location-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.site-header__location-list--mobile {
	font-size: 1rem;
	overflow: hidden;
	transition: max-height 325ms ease-out;
	max-height: 0;
}

.site-header__location-list--mobile .site-header__location-list__item:first-child {
	padding-top: 1.25rem;
}

.site-header__location-list__item__link {
	font-size: 1.75rem;
	font-weight: var(--font-weight-bold);
	text-decoration: none;
	text-transform: uppercase;
}

.site-header__location-list__item__link:not(.is-active) {
	color: var(--color-white);
	text-shadow:
		-1px -1px 0 var(--color-stoplight),
		1px -1px 0 var(--color-stoplight),
		-1px 1px 0 var(--color-stoplight),
		1px 1px 0 var(--color-stoplight);
}

.site-header__location-list__item__link.is-active {
	color: var(--color-stoplight);
}

.site-header__location-list__item__link:not(.is-active):hover {
	text-shadow:
		-1px -1px 0 var(--color-electric),
		1px -1px 0 var(--color-electric),
		-1px 1px 0 var(--color-electric),
		1px 1px 0 var(--color-electric);
}

@media (min-width: 1024px) {
	.site-header__location-list__item__link {
		font-size: 2.25rem;
	}
}

#mobile-locations-checkbox:checked + .site-header__location-switch__trigger .icon {
	transform: rotate(-90deg);
}

#mobile-locations-checkbox:checked ~ .site-header__location-list--mobile {
	max-height: 300px;
}

#mobile-navigation-checkbox:checked + .site-header-small .site-header-small__main {
	opacity: 1;
	transform: translateY(0);
	visibility: visible;
}

#mobile-navigation-checkbox:checked + .site-header-small .site-header-small__bottom {
	transform: translateY(0);
}

#mobile-navigation-checkbox:checked + .site-header-small .site-header-small__menu-toggle {
	transform: translateX(5px);
}

#mobile-navigation-checkbox:checked + .site-header-small .site-header-small__menu-toggle::before {
	transform: rotate(45deg) translateY(100%);
}

#mobile-navigation-checkbox:checked + .site-header-small .site-header-small__menu-toggle::after {
	transform: rotate(-45deg) translateY(-100%);
}

/* Location information for location sub menu */
.site-header__sub-locations-menu__info {
	color: var(--color-white);
	display: none;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	text-align: center;
	height: 100%;
}

.site-header__sub-locations-menu__info[active] {
	display: flex;
}
