.language-picker {
	background-color: var(--color-mercury);
	box-sizing: border-box;
	list-style: none;
	margin: 0;
	padding: 1rem;
}

.language-picker__item {
	color: currentColor;
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	text-align: center;
	text-decoration: none;
}

a.language-picker__item:hover {
	opacity: 1;
	text-decoration: underline;
}
