@media (--mq-from-small) {
	.featured-boxes {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}
}

@media (--mq-from-small) {
	.featured-boxes.featured-boxes--center {
		justify-content: center;
	}
}

.featured-boxes__item {
	flex-grow: 0;
	flex-shrink: 1;
}

@media (--mq-from-small) {
	.featured-boxes__item {
		flex-basis: calc(50% - (var(--space-medium) / 2));
	}
}

@media (--mq-from-small) and (--mq-up-to-wide) {
	.featured-boxes__item:nth-child(even) {
		margin-left: var(--space-medium);
	}
}

@media (--mq-from-wide) {
	.featured-boxes__item {
		flex-basis: calc(33.3333% - (var(--space-medium) * 2 / 3));
	}

	.featured-boxes__item:nth-child(3n),
	.featured-boxes__item:nth-child(3n - 1) {
		margin-left: var(--space-medium);
	}
}
