:root {
	/* Colors. */
	--color-electric: #3300ff;
	--color-flamingo: #fad2d2;
	--color-mercury: #e6e6e6;
	--color-pitch: #000000;
	--color-stoplight: #f02828;
	--color-white: #ffffff;
	--color-grey: #cecece;
	--color-darkgrey: #939393;

	/* Fonts. */
	--font-family: "Sneak", "Helvetica Neue", "Helvetica", Arial sans-serif;
	--font-weight-regular: 400;
	--font-weight-bold: 500;

	/* Spaces. */
	--space-x-small: 2rem;
	--space-small: 2.25rem;
	--space-medium: 3rem;
	--space-large: 4rem;
	--space-x-large: 5rem;

	/* Navigation gutters. */
	--navigation-gutter-medium: 1.25rem;
	--navigation-gutter-wide: 2rem;
	--navigation-gutter-large: 4rem;

	/* Timing. */
	--time-x-small: 64ms;
	--time-small: 96ms;
	--time-medium: 208ms;
	--time-long: 320ms;
	--time-large: 400ms;
	--time-xlarge: 800ms;
	--ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
	--ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	--ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);

	/* Pothole. */
	--pothole-width: 90px;
	--pothole-height: 36px;
	--pothole-width-from-xsmall: 130px;
	--pothole-height-from-xsmall: 52px;

	/* Header heights for anchor offset. */
	--header-height-xsmall: 61px;
	--header-height-small: 61px;
	--header-height-medium: 51px;
	--header-height-wide: 66px;
	--header-height-large: 66px;

	/* Z-indices */
	--z-index-site-header-primary: 99;
	--z-index-site-header-secondary: 150;
	--z-index-site-header-small: 101;
	--z-index-site-header-small-bottom: 100;
	/*  */
	--z-index-booking-sidepanel-dialog: 200;
	--z-index-booking-sidepanel: 201;
	/*  */
	--z-index-cookie-notice: 100;
}
