@media (max-width: 1023px) {
	.navigation-list {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

.navigation-list__sub-list,
.navigation-list {
	list-style: none;
	padding-left: 0;
}

@media (max-width: 1023px) {
	.navigation-list__item,
	.navigation-list__logo {
		margin-bottom: var(--space-medium);
	}
}

@media (min-width: 1024px) {
	.navigation-list__item,
	.navigation-list__logo {
		display: inline-block;
		vertical-align: middle;
	}

	.navigation-list__item + .navigation-list__item,
	.navigation-list__item + .navigation-list__logo,
	.navigation-list__logo + .navigation-list__item,
	.navigation-list__logo + .navigation-list__logo {
		margin-left: var(--navigation-gutter-medium);
	}

	@media (min-width: 1380px) {
		.navigation-list__item + .navigation-list__item,
		.navigation-list__item + .navigation-list__logo,
		.navigation-list__logo + .navigation-list__item,
		.navigation-list__logo + .navigation-list__logo {
			margin-left: var(--navigation-gutter-wide);
		}
	}

	@media (min-width: 1680px) {
		.navigation-list__item + .navigation-list__item,
		.navigation-list__item + .navigation-list__logo,
		.navigation-list__logo + .navigation-list__item,
		.navigation-list__logo + .navigation-list__logo {
			margin-left: var(--navigation-gutter-large);
		}
	}
}

@media (max-width: 1023px) {
	.navigation-list__logo {
		display: none;
	}
}

.navigation-list__item.navigation-list__item--children {
	transition: min-width var(--time-long) var(--ease-out-quad) var(--time-long);
	min-width: 0;
}

.navigation-list__item.navigation-list__item--children:hover {
	transition: min-width var(--time-long) var(--ease-out-quad);
	min-width: 100px;
}

.navigation-list__item input[type="checkbox"] {
	display: none;
	pointer-events: none;
}

.navigation-list__link {
	color: var(--color-pitch);
	position: relative;
	text-decoration: none;
	transition: color var(--time-x-small) var(--ease-out-quad);
}

.navigation-list__link.navigation-list__link--current,
.navigation-list__link.navigation-list__link--current-ancestor,
.navigation-list__item:hover .navigation-list__link {
	color: var(--color-stoplight);
}

.site-header:not(.is-scrolled) .navigation-list__link.navigation-list__link--current,
.navigation-list__link:hover {
	text-decoration: underline;
}

.navigation-list__link::before {
	background-color: var(--color-flamingo);
	content: "";
	display: block;
	opacity: 0;
	position: absolute;
	top: -0.25rem;
	left: -0.5rem;
	transition: opacity var(--time-x-small) var(--ease-out-quad);
	width: calc(100% + 1rem);
	height: calc(100% + 0.5rem);
}

@media (min-width: 1680px) {
	.navigation-list__link::before {
		top: -0.5rem;
		left: -1rem;
		width: calc(100% + 2rem);
		height: calc(100% + 1rem);
	}
}

.navigation-list__link.navigation-list__link--current::before,
.navigation-list__link.navigation-list__link--current-ancestor::before,
.navigation-list__item:hover .navigation-list__link::before {
	opacity: 1;
}

.navigation-list__item--children .navigation-list__link {
	transition: color var(--time-x-small) var(--ease-out-quad) var(--time-long);
}

.navigation-list__item--children .navigation-list__link::before {
	transition: opacity var(--time-x-small) var(--ease-out-quad) var(--time-long);
}

.navigation-list__item--children:hover .navigation-list__link {
	transition: color var(--time-x-small) var(--ease-out-quad);
}

.navigation-list__item--children:hover .navigation-list__link::before {
	transition: opacity var(--time-x-small) var(--ease-out-quad);
}

.navigation-list__link span {
	position: relative;
	z-index: 1;
}

.navigation-list__link.navigation-list__link--subnav-toggle {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	display: inline-block;
	outline: 0;
	padding: 0;
}

.navigation-list__link.navigation-list__link--subnav-toggle:hover {
	cursor: pointer;
}

.navigation-list__item {
	position: relative;
	text-align: center;
}

.navigation-list__sub-list {
	background-color: var(--color-flamingo);
	box-sizing: border-box;
	min-width: 180px;
	z-index: 1;
}

@media (max-width: 1023px) {
	.navigation-list__sub-list {
		display: none;
		padding: 1.25rem;
	}

	.navigation-list__link--current + .navigation-list__sub-list,
	.navigation-list__link--current-ancestor + .navigation-list__sub-list,
	.navigation-list__link--subnav-toggle + input:checked + .navigation-list__sub-list {
		display: block;
		z-index: 4;
	}
}

@media (min-width: 1024px) {
	.navigation-list__sub-list {
		line-height: 40px;
		opacity: 0;
		padding-top: 0.5rem;
		padding-right: 1rem;
		padding-bottom: 0.5rem;
		padding-left: 1rem;
		position: absolute;
		text-align: left;
		top: calc(100% + 0.125rem);
		left: 50%;
		transform: translate(-50%, 0) scale3d(1, 0, 1);
		transform-origin: top center;
		transition:
			opacity var(--time-long) var(--ease-out-quad),
			transform var(--time-long) var(--ease-out-quad);
		white-space: nowrap;
	}

	.navigation-list__item--children:hover .navigation-list__sub-list {
		opacity: 1;
		transform: translate(-50%, 0) scale3d(1, 1, 1);
		transition:
			opacity var(--time-small) var(--ease-out-quad),
			transform var(--time-long) var(--ease-out-quad);
	}
}

@media (min-width: 1680px) {
	.navigation-list__sub-list {
		top: calc(100% + 0.25rem);
	}
}

.navigation-list__sub-item {
	opacity: 0;
	transition: opacity var(--time-x-small) var(--ease-out-quad) 0s;
}

@media (max-width: 1023px) {
	.navigation-list__sub-item {
		opacity: 1;
	}
}

.navigation-list__item--children:hover .navigation-list__sub-item {
	opacity: 1;
	transition: opacity var(--time-x-small) var(--ease-out-quad) var(--time-long);
}

.navigation-list__sub-link {
	color: var(--color-pitch);
	text-decoration: none;
}

.navigation-list__sub-link.navigation-list__sub-link--current,
.navigation-list__sub-link:hover {
	color: var(--color-stoplight);
	text-decoration: none;
}

@media (min-width: 1024px) {
	.navigation-list__sub-link {
		text-decoration: underline;
	}

	.navigation-list__sub-link.navigation-list__sub-link--current,
	.navigation-list__sub-link:hover {
		text-decoration: underline;
	}
}
