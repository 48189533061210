.u-clearfix::after {
	clear: both;
	content: " ";
	display: table;
}

.u-visually-hidden {
	border: none !important;
	clip: rect(0 0 0 0) !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
}

.u-anchor:target::before {
	content: "";
	display: block;
	margin-top: calc(-1 * var(--header-height-xsmall));
	height: var(--header-height-xsmall);
}

@media (--mq-from-small) {
	.u-anchor:target::before {
		margin-top: calc(-1 * var(--header-height-small));
		height: var(--header-height-small);
	}
}

@media (--mq-from-medium) {
	.u-anchor:target::before {
		margin-top: calc(-1 * var(--header-height-medium));
		height: var(--header-height-medium);
	}
}

@media (--mq-from-wide) {
	.u-anchor:target::before {
		margin-top: calc(-1 * var(--header-height-wide));
		height: var(--header-height-wide);
	}
}

@media (--mq-from-large) {
	.u-anchor:target::before {
		margin-top: calc(-1 * var(--header-height-large));
		height: var(--header-height-large);
	}
}
