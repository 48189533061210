.section {
	box-sizing: border-box;
	width: 100%;
}

.section--small {
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.section--small {
		padding-top: var(--space-medium);
		padding-bottom: var(--space-medium);
	}
}

.section--large {
	padding-top: var(--space-medium);
	padding-bottom: var(--space-medium);
}

@media (--mq-from-medium) {
	.section--large {
		padding-top: calc(var(--space-medium) * 2);
		padding-bottom: calc(var(--space-medium) * 2);
	}
}
