.doormat {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -0.625rem;
	padding-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.doormat {
		padding-bottom: var(--space-medium);
	}
}

.doormat__block {
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
}

.doormat__block::before {
	content: "";
	display: block;
	padding-top: 50%;
}

.doormat__block.doormat__block--is-square-on-small {
	flex-basis: calc(25% - 20px);
}

.doormat__block.doormat__block--is-square-on-small::before {
	padding-top: 100%;
}

@media (--mq-from-xsmall) {
	.doormat__block::before {
		padding-top: 30%;
	}
}

@media (--mq-from-small) {
	.doormat__block::before {
		padding-top: 25%;
	}
}

@media (--mq-from-medium) {
	.doormat__block {
		flex-basis: calc(25% - 20px);
		padding-top: 0;
		padding-bottom: 0;
	}

	.doormat__block::before {
		padding-top: 100%;
	}
}

.doormat__block__inner {
	display: flex;
	padding: 0.625rem;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.doormat__block__content {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
}
