@font-face {
	font-family: "Sneak Mono";
	src: url("../fonts/sneak-mono.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Sneak";
	src: url("../fonts/sneak-regular-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Sneak";
	src: url("../fonts/sneak-regular-italic-webfont.woff2") format("woff2");
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "Sneak";
	src: url("../fonts/sneak-bold-webfont.woff2") format("woff2");
	font-style: normal;
	font-weight: 500;
}
