/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.booking-sidepanel-dialog {
	display: none;
	opacity: 0;
	pointer-events: none;
	position: relative;
	z-index: var(--z-index-booking-sidepanel-dialog);
}

.booking-sidepanel-dialog[data-state="opening"],
.booking-sidepanel-dialog[data-state="open"],
.booking-sidepanel-dialog[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.booking-sidepanel-dialog:focus {
	outline: none;
}

.booking-sidepanel-dialog[data-state="opening"] .booking-sidepanel,
.booking-sidepanel-dialog[data-state="open"] .booking-sidepanel,
.booking-sidepanel-dialog[data-state="closing"] .booking-sidepanel {
	display: block;
	max-width: 467px;
	z-index: var(--z-index-booking-sidepanel);
}

.booking-sidepanel-backdrop {
	background-color: var(--color-pitch);
	opacity: 0.5;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: var(--z-index-booking-sidepanel-dialog);
}

.booking-sidepanel {
	background-color: var(--color-stoplight);
	box-sizing: border-box;
	color: var(--color-white);
	overflow-y: auto;
	padding: 1.25rem;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	transform: translateX(100%);
	width: 100%;
}

@media (--mq-from-medium) {
	.booking-sidepanel {
		padding: 3.125rem;
	}
}

.booking-sidepanel__cancel-button {
	background-color: var(--color-stoplight);
	text-align: center;
}

@media (--mq-up-to-medium) {
	.booking-sidepanel__cancel-button {
		padding: 1.25rem;
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}
}

@media (--mq-from-medium) {
	.booking-sidepanel__cancel-button {
		margin-bottom: 2.875rem;
		text-align: right;
	}
}

.booking-sidepanel__cancel-button__trigger {
	background-color: var(--color-white);
	border: none;
	border-radius: 30px;
	color: var(--color-stoplight);
	cursor: pointer;
	font-size: 1.125rem;
	font-weight: var(--font-weight-bold);
	padding: 12px 38px 9px;
	text-transform: uppercase;
}

.booking-sidepanel__tabs {
	position: relative;
}

.booking-sidepanel__menu__border {
	border-bottom: 1px dashed var(--color-white);
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
}

.booking-sidepanel__tabs__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.booking-sidepanel__tabs__list:focus {
	outline: 0;
}

.booking-sidepanel__tabs__list::after {
	border-bottom: 1px dashed var(--color-white);
	content: "";
	position: absolute;
	bottom: 0;
	width: 100%;
}

.booking-sidepanel__tabs__list__item {
	appearance: none;
	background-color: transparent;
	border: none;
	border-radius: 0;
	color: var(--color-white);
	cursor: pointer;
	display: block;
	font-size: 1rem;
	padding: 0 0 1.25rem 0;
	text-decoration: underline;
}

.booking-sidepanel__tabs__list__item:hover,
.booking-sidepanel__tabs__list__item[aria-selected="true"] {
	border-bottom: 1px solid var(--color-white);
	font-weight: var(--font-weight-bold);
}

.booking-sidepanel__tabs__list__item:hover .booking-sidepanel__tabs__list__item__label,
.booking-sidepanel__tabs__list__item[aria-selected="true"] .booking-sidepanel__tabs__list__item__label {
	display: inline-block;
	letter-spacing: -0.4px;
	text-decoration: underline;
	transform: translateY(2px);
}

.booking-sidepanel__tabs__list__item:not(:last-child) {
	margin-right: 0.625rem;
}

@media (--mq-from-medium) {
	.booking-sidepanel__tabs__list__item:not(:last-child) {
		margin-right: 1.125rem;
	}
}

.booking-sidepanel__tabs__content {
	font-size: 0.875rem;
	margin-top: 1.125rem;
}

@media (--mq-from-medium) {
	.booking-sidepanel__tabs__content {
		font-size: 1rem;
	}
}

.booking-sidepanel__tabs__content__button {
	appearance: none;
	background-color: var(--color-pitch);
	border: none;
	box-sizing: border-box;
	color: var(--color-white);
	cursor: pointer;
	display: block;
	font-size: 1.125rem;
	margin-top: 1.25rem;
	padding: 0.75rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;
}

.booking-sidepanel__tabs__content__label {
	display: block;
	margin-top: 0.625rem;
	margin-bottom: 0.875rem;
}

.booking-sidepanel__tabs__content__field-wrapper {
	position: relative;
}

.booking-sidepanel__tabs__content__field-wrapper .icon-next {
	color: var(--color-pitch);
	display: block;
	pointer-events: none;
	position: absolute;
	top: 50%;
	right: 1.375rem;
	transform: rotate(90deg) translateX(-50%);
	width: 0.5625rem;
	height: 0.75rem;
}

.booking-sidepanel__tabs__content__field {
	appearance: none;
	border: none;
	border-radius: 0;
	box-sizing: border-box;
	display: block;
	margin-top: 0.875rem;
	margin-bottom: 0.625rem;
	padding: 0.625rem;
	width: 100%;
	min-height: 44px;
}

p.booking-sidepanel__tabs__content__text {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

.booking-sidepanel__tabs__content__iframe {
	background-color: var(--color-white);
	width: 100%;
	height: 326px;
}

@media (min-height: 667px) {
	.booking-sidepanel__tabs__content__iframe {
		height: 426px;
	}
}

@media (min-height: 736px) {
	.booking-sidepanel__tabs__content__iframe {
		height: 492px;
	}
}

@media (--mq-from-medium) {
	.booking-sidepanel__tabs__content__iframe {
		height: 612px;
	}
}

.booking-sidepanel__tabs__content__switch[selected-location] > [location] {
	display: none;
}

.booking-sidepanel__tabs__content__switch[selected-location="antwerp"] > [location="antwerp"] {
	display: block;
}

.booking-sidepanel__tabs__content__switch[selected-location="brussels"] > [location="brussels"] {
	display: block;
}

.booking-sidepanel__tabs__content__switch[selected-location="liege"] > [location="liege"] {
	display: block;
}
