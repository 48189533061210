.l-single-detail {
}

.l-single-detail__go-back {
	padding-block: 0.9375rem;
}

.l-single-detail__hero__intro__subtitle {
	color: var(--color-pitch);
	font-weight: var(--font-weight-bold);
	margin-bottom: 0 !important;
	text-transform: capitalize;
}

.l-single-detail__hero__intro__title {
	margin: 0;
	text-transform: uppercase;
}

.l-single-detail__content {
	background-color: var(--color-mercury);
}

/* #region Wrapper specific for detail content */
@media (--mq-from-small) {
	.l-single-detail__content .wrapper {
		padding-inline: calc((100% - 37.5rem) / 2);
	}
}
/* #endregion Wrapper specific for detail content */
