.cta-teaser-wrapper {
	--color-cta-background: var(--color-white);
	--color-cta-text: var(--color-black);
	--color-cta-menu-background: var(--color-electric);
	--color-cta-menu-background-hover: var(--color-pitch);
	--color-cta-menu-text: var(--color-white);
}

.cta-teaser-wrapper[data-theme="electric"] {
	--color-cta-background: var(--color-electric);
	--color-cta-text: var(--color-white);
	--color-cta-menu-background: var(--color-flamingo);
	--color-cta-menu-background-hover: var(--color-white);
	--color-cta-menu-text: var(--color-electric);
}

.cta-teaser-wrapper[data-theme="flamingo"] {
	--color-cta-background: var(--color-flamingo);
	--color-cta-menu-text: var(--color-flamingo);
}

.cta-teaser-wrapper[data-theme="mercury"] {
	--color-cta-background: var(--color-mercury);
	--color-cta-menu-background: var(--color-pitch);
	--color-cta-menu-background-hover: var(--color-electric);
	--color-cta-menu-text: var(--color-mercury);
}

.cta-teaser {
	background-color: var(--color-cta-background);
	color: var(--color-cta-text);
	padding-inline: 20px;
	padding-block: 1.25rem;
}

/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser {
		padding-inline: 48px;
		padding-block: 2.5rem;
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.cta-teaser {
		padding-block: var(--space-medium);
	}
}

/* mq-from-large */
@media (--mq-from-wide) {
	.cta-teaser {
		padding-block: 6.25rem;
	}
}

.cta-teaser__inner {
	display: grid;
	grid-row-gap: 1.25rem;
	margin-inline: auto;
	max-width: 84rem;
}

/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser__inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser-wrapper[data-image-position="right"] .cta-teaser__inner {
		flex-direction: row-reverse;
	}
}

/* #region Media */
/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser__media {
		flex-grow: 0;
		flex-shrink: 0;
		width: 52%;
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.cta-teaser__media {
		width: 47%;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.cta-teaser__media {
		width: 48%;
	}
}

.cta-teaser__media__image {
	display: block;
	width: 100%;
	height: auto;
}
/* #endregion Media */

/* #region Info */
/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser__info-container {
		flex-grow: 0;
		flex-shrink: 0;
		width: 41%;
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.cta-teaser__info-container {
		width: 42%;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.cta-teaser__info-container {
		width: 33%;
	}
}

h2.cta-teaser__info__title {
	color: var(--color-cta-menu-background);
	font-weight: var(--font-weight-bold);
	margin-block-end: 1.25rem;
	text-transform: uppercase;
}

.cta-teaser__info > p {
	margin-block-end: 1.25rem;
}
/* #endregion Info */

/* #region Menu */
.cta-teaser__info__menu-container {
	position: relative;
	width: fit-content;
	max-width: 100%;
}

.cta-teaser__menu-button {
	-mrh-resets: button;
	background-color: var(--color-cta-menu-background);
	border-radius: 300px;
	color: var(--color-cta-menu-text);
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.36;
	padding-inline-start: 20px;
	padding-inline-end: 40px;
	padding-block: 0.625rem;
	position: relative;
	transition: background-color 96ms linear;
	max-width: 100%;
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.cta-teaser__menu-button {
		font-size: 1rem;
		line-height: 1.375;
	}
}

.cta-teaser__menu-button:hover,
.cta-teaser__menu-button[aria-expanded="true"] {
	background-color: var(--color-cta-menu-background-hover);
}

.cta-teaser__menu-button::after {
	background-color: var(--color-cta-menu-text);
	content: "";
	display: block;
	mask-image: url("../images/chevron-down.svg");
	mask-repeat: no-repeat;
	mask-size: 100%;
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	transition: transform 192ms ease-out;
	width: 0.625rem;
	height: 0.375rem;
}

.cta-teaser__menu-button[aria-expanded="true"]::after {
	transform: translateY(-50%) rotate(180deg);
}

.cta-teaser__menu-button > span {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.cta-teaser__menu-list {
	background-color: var(--color-cta-menu-background);
	border-radius: 10px;
	color: var(--color-cta-menu-text);
	display: none;
	list-style: none;
	padding-inline: 0;
	padding-block: 0.625rem;
	width: max-content;
	min-width: 100%;
}

/* mq-from-small */
@media (--mq-from-small) {
	.cta-teaser__menu-list {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: 20;
	}
}

.cta-teaser__menu-list.mr-menu--expanded {
	display: block;
}

.cta-teaser__menu-list__link {
	color: var(--color-cta-menu-text);
	display: block;
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.36;
	padding-inline: 20px;
	padding-block: 0.625rem;
	text-decoration: none;
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.cta-teaser__menu-list__link {
		font-size: 1rem;
		line-height: 1.375;
	}
}

.cta-teaser__menu-list__link:hover {
	text-decoration: underline;
}
/* #endregion Menu */
