.action-panel {
	background-color: var(--color-flamingo);
	position: relative;
	z-index: 15;
}

@media (--mq-from-small) {
	.action-panel.action-panel--fill-height {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 121px);
	}
}

@media (--mq-from-medium) {
	.action-panel.action-panel--fill-height {
		min-height: calc(100vh - 68px);
	}
}

@media (--mq-from-wide) {
	.action-panel.action-panel--fill-height {
		min-height: calc(100vh - 77px);
	}
}

@media (--mq-from-large) {
	.action-panel.action-panel--fill-height {
		min-height: calc(100vh - 89px);
	}
}

.action-panel.action-panel--electric {
	background-color: var(--color-electric);
}

.action-panel.action-panel--mercury {
	background-color: var(--color-mercury);
}

.action-panel.action-panel--stoplight {
	background-color: var(--color-stoplight);
}

.action-panel__content {
	position: relative;
	z-index: 2;
}

@media (--mq-from-small) {
	.action-panel--fill-height .action-panel__content {
		flex-basis: auto;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.action-panel__slideshow,
.action-panel__video-embed-player {
	position: relative;
	z-index: 1;
}

@media (--mq-from-small) {
	.action-panel--fill-height .action-panel__slideshow {
		flex-basis: calc(100vh - 451px); /* Fallback for IE11 */
		flex-grow: 1;
		flex-shrink: 1;
		overflow: hidden;
	}
}

@media (--mq-from-medium) {
	.action-panel--fill-height .action-panel__slideshow {
		flex-basis: calc(100vh - 398px); /* Fallback for IE11 */
		min-height: 3.625rem;
	}
}

@media (min-width: 1280px) {
	.action-panel--fill-height .action-panel__slideshow {
		flex-basis: calc(100vh - 355px); /* Fallback for IE11 */
		min-height: 3.75rem;
	}
}

@media (--mq-from-wide) {
	.action-panel--fill-height .action-panel__slideshow {
		flex-basis: calc(100vh - 309px); /* Fallback for IE11 */
		min-height: 3.8125rem;
	}
}

@media (--mq-from-large) {
	.action-panel--fill-height .action-panel__slideshow {
		flex-basis: calc(100vh - 321px); /* Fallback for IE11 */
	}
}

.action-panel__booking-form {
	box-sizing: border-box;
}

@media (--mq-from-medium) {
	.action-panel__booking-form {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (min-width: 90em) {
	.action-panel__booking-form {
		padding-right: calc((100% - 84rem) / 2);
		padding-left: calc((100% - 84rem) / 2);
	}
}

@media (--mq-from-medium) {
	.action-panel__booking-form:not(:only-child) {
		margin-bottom: -3.625rem;
		transform: translate(0, -50%);
	}
}

@media (min-width: 1280px) {
	.action-panel__booking-form:not(:only-child) {
		margin-bottom: -3.75rem;
	}
}

@media (--mq-from-wide) {
	.action-panel__booking-form:not(:only-child) {
		margin-bottom: -3.8125rem;
	}
}

@media (--mq-from-medium) {
	.action-panel__booking-form:only-child {
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}

.action-panel__usps:only-child,
.action-panel__booking-form + .action-panel__usps {
	padding-top: 1.25rem;
}

@media (--mq-from-medium) {
	.action-panel__usps:only-child {
		padding-top: 2.5rem;
	}
}

.action-panel--electric .action-panel__service-icons {
	filter: invert(1);
}

.action-panel__service-icons,
.action-panel__usps {
	color: var(--color-stoplight);
}

.action-panel--electric .action-panel__service-icons,
.action-panel--electric .action-panel__usps {
	color: var(--color-white);
}

.action-panel--mercury .action-panel__service-icons,
.action-panel--mercury .action-panel__usps {
	color: var(--color-electric);
}

.action-panel--stoplight .action-panel__service-icons,
.action-panel--stoplight .action-panel__usps {
	color: var(--color-pitch);
}
