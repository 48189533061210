.popup {
	display: none;
	opacity: 0;
	pointer-events: none;
}

.popup[data-state="opening"],
.popup[data-state="open"],
.popup[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
	position: fixed;
	z-index: 10000; /* higher then the overlay component */
}

.popup[data-state="open"] {
	opacity: 1;
}

.popup:focus {
	outline: none;
}

.popup__content {
	display: none;
	opacity: 0;
}

.popup[data-state="opening"] .popup__content,
.popup[data-state="open"] .popup__content,
.popup[data-state="closing"] .popup__content {
	display: block;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	max-width: 500px;
	z-index: 10002;
}

.popup[data-state="open"] .popup__content {
	opacity: 1;
}

.popup[data-state="opening"] .popup__content__wrapper,
.popup[data-state="open"] .popup__content__wrapper,
.popup[data-state="closing"] .popup__content__wrapper {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	width: 100%;
	max-height: calc(100vh - 35px);
}

@media (--mq-from-small) {
	.popup[data-state="opening"] .popup__content__wrapper,
	.popup[data-state="open"] .popup__content__wrapper,
	.popup[data-state="closing"] .popup__content__wrapper {
		max-height: calc(100vh - 42px);
	}
}

.popup__content__wrapper {
	text-decoration: none;
}

.popup[data-state="opening"] .popup__background,
.popup[data-state="open"] .popup__background,
.popup[data-state="closing"] .popup__background {
	background-color: rgb(0 0 0 / 0.5);
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10001;
}

.popup__row {
	padding: 1.25rem;
}

.popup__row.popup__row--full_width {
	padding: 0;
}

@media (--mq-from-small) {
	.popup__row.popup__row--text {
		padding-right: 0;
		padding-left: 0;
	}
}

.popup__row.popup__row--jot {
	overflow: auto;
	max-height: 320px;
}

.popup__button {
	margin-top: 0;
	text-align: center;
	text-transform: uppercase;
	width: 100%;
}
