.wrapper {
	box-sizing: border-box;
	margin-right: auto;
	margin-left: auto;
	padding-right: 1.25rem;
	padding-left: 1.25rem;
	width: 100%;
}

@media (--mq-from-medium) {
	.wrapper {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

@media (--mq-from-xsmall) {
	.wrapper.wrapper--small {
		padding-right: calc((100% - 30rem) / 2);
		padding-left: calc((100% - 30rem) / 2);
	}
}

@media (min-width: 90em) {
	.wrapper.wrapper--medium {
		padding-right: calc((100% - 84rem) / 2);
		padding-left: calc((100% - 84rem) / 2);
	}
}

@media (min-width: 104em) {
	.wrapper.wrapper--large {
		padding-right: calc((100% - 98rem) / 2);
		padding-left: calc((100% - 98rem) / 2);
	}
}

.wrapper.wrapper--full-width {
	padding-right: 0;
	padding-left: 0;
}
