.cookie-notice {
	background-color: var(--color-white);
	box-sizing: border-box;
	display: none;
	font-size: 0.625rem;
	line-height: 1.6;
	opacity: 0;
	padding: 0.625rem;
	pointer-events: none;
	position: fixed;
	top: var(--header-height-small);
	left: 0;
	width: 100%;
	z-index: var(--z-index-cookie-notice);
}

@media (--mq-from-medium) {
	.cookie-notice {
		font-size: 0.75rem;
		line-height: 1.5;
		top: auto;
		right: 58px;
		bottom: 58px;
		left: auto;
		width: 30.9375rem;
	}

	.cookie-notice.cookie-notice--with-newsletter-dialog {
		right: 220px;
	}
}

.cookie-notice[data-state="opening"],
.cookie-notice[data-state="open"],
.cookie-notice[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
}

.cookie-notice__cta-list {
	list-style: none;
	margin: 0.625rem 0 0;
	padding: 0;
}

@media (--mq-from-medium) {
	.cookie-notice__cta-list {
		margin-top: 0.875rem;
	}
}

.cookie-notice__cta-list__item {
	display: inline-block;
	margin-right: 0.3125rem;
}
