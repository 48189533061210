.text-media-row {
	background-color: #e6e6e6;
	color: black;
}

@media (--mq-from-small) {
	.text-media-row {
		display: flex;
	}
}

.text-media-row__text {
	box-sizing: border-box;
	padding: var(--space-medium) 1.25rem;
	text-align: center;
}

@media (--mq-from-small) {
	.text-media-row__text {
		display: flex;
		flex-shrink: 0;
		flex-flow: row nowrap;
		align-items: center;
		max-width: 40%;
	}
}

@media (--mq-from-medium) {
	.text-media-row__text {
		padding: calc(var(--space-medium) * 2) var(--space-medium);
	}
}

@media (min-width: 90em) {
	.text-media-row__text {
		padding-right: 2.5rem;
		padding-left: calc((100% - 84rem) / 2);
	}
}

@media (--mq-from-large) {
	.text-media-row__text {
		padding-right: 5rem;
	}
}

@media (min-width: 90em) {
	.text-media-row--right .text-media-row__text {
		padding-right: calc((100% - 84rem) / 2);
		padding-left: 2.5rem;
	}
}

@media (--mq-from-large) {
	.text-media-row--right .text-media-row__text {
		padding-right: calc((100% - 84rem) / 2);
		padding-left: 5rem;
	}
}

.text-media-row__media {
	background-color: #e6e6e6;
}

@media (--mq-from-small) {
	.text-media-row__media {
		flex-basis: 60%;
		flex-grow: 1;
		flex-shrink: 0;
		flex-flow: row nowrap;
		overflow: hidden;
		position: relative;
	}
}

.text-media-row--right .text-media-row__media {
	order: -1;
}

.text-media-row__media__image,
.text-media-row__media__video {
	width: 100%;
}

@media (--mq-from-small) {
	.text-media-row__media__image,
	.text-media-row__media__video {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: auto;
		min-width: 100%;
		max-width: none;
		height: auto;
		min-height: 100%;
	}
}

@supports (object-fit: cover) {
	@media (--mq-from-small) {
		.text-media-row__media__image,
		.text-media-row__media__video {
			object-fit: cover;
			object-position: center;
			position: relative;
			top: auto;
			left: auto;
			transform: none;
			width: 100%;
			height: 100%;
		}
	}
}

.text-media-row__media__video[data-aspect-ratio="1:1"] {
	min-height: 100vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="1:1"] {
		min-height: 60vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="1:2"] {
	min-height: 150vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="1:2"] {
		min-height: 120vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="2:1"] {
	min-height: 50vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="2:1"] {
		min-height: 30vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="2:3"] {
	min-height: 150vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="2:3"] {
		min-height: 90vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="3:2"] {
	min-height: 66.6666vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="3:2"] {
		min-height: 40vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="3:4"] {
	min-height: 133.3333vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="3:4"] {
		min-height: 80vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="4:3"] {
	min-height: 75vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="4:3"] {
		min-height: 45vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="16:9"] {
	min-height: 56.25vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="16:9"] {
		min-height: 33.75vw;
	}
}

.text-media-row__media__video[data-aspect-ratio="21:9"] {
	min-height: 42.8571vw;
}

@media (--mq-from-small) {
	.text-media-row__media__video[data-aspect-ratio="21:9"] {
		min-height: 25.7143vw;
	}
}
