.theme {
}

.theme--electric {
	background-color: var(--color-electric);
	color: var(--color-white);
}

.theme--flamingo {
	background-color: var(--color-flamingo);
	color: var(--color-stoplight);
}

.theme--mercury {
	background-color: var(--color-mercury);
	color: var(--color-pitch);
}

.theme--pitch {
	background-color: var(--color-pitch);
	color: var(--color-stoplight);
}

.theme--stoplight {
	background-color: var(--color-stoplight);
	color: var(--color-white);
}

.theme--white {
	background-color: var(--color-white);
	color: var(--color-pitch);
}

.theme--grey {
	background-color: var(--color-grey);
	color: var(--color-pitch);
}
