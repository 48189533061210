.footer-navigation-primary {
	border: 1px solid currentColor;
	box-sizing: border-box;
	color: var(--color-stoplight);
	display: flex;
	flex-wrap: wrap;
	padding: 1.2rem;
	justify-content: center;
	text-align: center;
}

@media (--mq-from-medium) {
	.footer-navigation-primary {
		flex-wrap: nowrap;
		padding: 5.5rem;
		justify-content: space-between;
		width: 100%;
	}
}

.footer-navigation-primary.footer-navigation-primary--single {
	justify-content: center;
}

.footer-navigation-primary__slot {
	margin-bottom: 1rem;
	text-align: center;
	width: 100%;
}

@media (--mq-from-medium) {
	.footer-navigation-primary__slot {
		flex-basis: 50%;
		margin-right: 3.125rem;
		margin-bottom: 0;
		margin-left: 3.125rem;
		width: auto;
	}
}

.footer-navigation-primary__slot:last-child {
	margin-bottom: 0;
}

@media (--mq-from-small) {
	.footer-navigation-primary__social {
		display: flex;
		justify-content: center;
	}
}

.footer-navigation-primary__social__list,
.footer-navigation-primary__social__twitter-handle {
	margin-bottom: 1rem;
	text-align: center;
	width: 100%;
}

@media (--mq-from-small) {
	.footer-navigation-primary__social__list,
	.footer-navigation-primary__social__twitter-handle {
		margin-bottom: 0;
		width: auto;
	}
}

.footer-navigation-primary__social__list:last-child,
.footer-navigation-primary__social__twitter-handle:last-child {
	margin-bottom: 0;
}

@media (--mq-from-small) {
	.footer-navigation-primary__social__twitter-handle {
		margin-right: 3.125rem;
	}
}

.footer-navigation-primary__twitter {
	display: inline-block;
}

.footer-navigation-primary__link {
	border: none;
	color: currentColor;
	text-decoration: none;
}

.footer-navigation-primary__list {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

.footer-navigation-primary__list__item {
	margin-right: 7px;
	margin-left: 7px;
}

.footer-navigation-primary__list--locations .footer-navigation-primary__list__item:not(:first-child)::before {
	content: " - ";
	margin-right: 7px;
}

.footer-navigation-primary__list__item .icon {
	width: 1.5rem;
	height: 1.5rem;
}

.footer-navigation-primary__list__item .icon.icon- {
	display: none;
}

.footer-navigation-primary__label:not(:only-child) {
	display: none;
}
