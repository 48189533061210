/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
.newsletter-dialog {
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 99;
}

@media (min-width: 568px) {
	.newsletter-dialog {
		right: 58px;
		bottom: 58px;
		left: auto;
	}
}

/* #region Box. */
.newsletter-dialog__box {
	background-color: var(--color-white);
	border: solid 3px var(--color-stoplight);
	box-sizing: border-box;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	bottom: 80px;
	visibility: hidden;
	width: calc(100vw - (2 * 20px));
	z-index: 1;
}

.newsletter-dialog__box:focus {
	outline: none;
}

.newsletter-dialog__box[data-state="opening"],
.newsletter-dialog__box[data-state="open"],
.newsletter-dialog__box[data-state="closing"] {
	opacity: 1;
	pointer-events: auto;
	visibility: visible;
}

@media (min-width: 568px) {
	.newsletter-dialog__box {
		bottom: 120px;
		width: 350px;
	}
}
/* #endregion */

/* #region Close button. */
.newsletter-dialog__box__close-button {
	appearance: none;
	background: transparent;
	border-top: none;
	border-right: none;
	border-bottom: 1px dashed var(--color-stoplight);
	border-left: 1px dashed var(--color-stoplight);
	color: var(--color-stoplight);
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	z-index: 2;
}

.newsletter-dialog__box__close-button .icon {
	width: 0.625rem;
	height: 0.625rem;
}
/* #endregion */

/* #region Scroll container. */
.newsletter-dialog__box__scroll-container {
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	width: 100%;
	height: auto;
	max-height: calc(100vh - 120px);
	z-index: 1;
}

@media (min-width: 568px) {
	.newsletter-dialog__box__scroll-container {
		max-height: calc(100vh - 200px);
	}
}
/* #endregion */

/* #region Content. */
.newsletter-dialog__box__content {
	overflow: hidden;
	height: auto;
	min-height: 100%;
}

.newsletter-dialog__box__label {
	color: var(--color-stoplight);
	display: block;
	padding: 22px 49px 0 20px;
}

.newsletter-dialog__box__form {
	padding: 0 20px 15px;
}
/* #endregion */

/* #region Pothole. */
.newsletter-dialog__pothole {
	position: absolute;
	right: 0;
	bottom: 0;
}

/* #region Trigger button. */
.newsletter-dialog__trigger-button {
	appearance: none;
	background: transparent;
	border: none;
	border-radius: 0;
	cursor: pointer;
	font-size: 0;
	padding: 0;
	position: relative;
	width: var(--pothole-width);
	height: var(--pothole-height);
}

@media (min-width: 568px) {
	.newsletter-dialog__trigger-button {
		width: var(--pothole-width-from-xsmall);
		height: var(--pothole-height-from-xsmall);
	}
}
/* #endregion */

/* #region Subscribe Animation. */
.newsletter-dialog__trigger-button__subscribe-animation {
	background-image: url("../images/pothole-subscribe-spritesheet-mobile.png");
	background-position: top left;
	background-repeat: no-repeat;
	background-size: calc(var(--pothole-width) * 146) 100%;
	pointer-events: none;
	position: absolute;
	bottom: 0;
	left: 0;
	width: var(--pothole-width);
	height: calc(var(--pothole-width) / 260 * 494); /* Frames are 260 by 494. */
}

@media (min-width: 568px) {
	.newsletter-dialog__trigger-button__subscribe-animation {
		background-image: url("../images/pothole-subscribe-spritesheet.png");
		background-size: calc(var(--pothole-width-from-xsmall) * 146) 100%;
		width: var(--pothole-width-from-xsmall);
		height: calc(var(--pothole-width-from-xsmall) / 260 * 494); /* Frames are 260 by 494. */
	}
}

.newsletter-dialog__trigger-button__subscribe-animation[run] {
	animation-duration: 6000ms;
	animation-iteration-count: 1;
	animation-name: RUN_SUBSCRIBE;
	animation-timing-function: steps(146);
}

@media (min-width: 568px) {
	.newsletter-dialog__trigger-button__subscribe-animation[run] {
		animation-name: RUN_SUBSCRIBE_FROM_XSMALL_UP;
	}
}

/* Pothole width x 146 frames. */

@keyframes RUN_SUBSCRIBE {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width) * -146);
	}
}

/* Pothole width x 146 frames. */

@keyframes RUN_SUBSCRIBE_FROM_XSMALL_UP {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width-from-xsmall) * -146);
	}
}
/* #endregion */

/* #region Hover Animation. */
/* NTH: keep looping last couple of frames. */
@media (hover: hover) {
	.newsletter-dialog__trigger-button__hover-animation {
		background-image: url("../images/pothole-hover-spritesheet.png");
		background-position: top left;
		background-repeat: no-repeat;
		background-size: calc(var(--pothole-width) * 17) 100%;
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: var(--pothole-width);
		height: var(--pothole-width); /* Has 1:1 aspect ratio. */
	}

	@media (min-width: 568px) {
		.newsletter-dialog__trigger-button__hover-animation {
			background-size: calc(var(--pothole-width-from-xsmall) * 17) 100%;
			width: var(--pothole-width-from-xsmall);
			height: var(--pothole-width-from-xsmall); /* Has 1:1 aspect ratio. */
		}
	}

	.newsletter-dialog__trigger-button__hover-animation[run] {
		animation-duration: 672ms;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		animation-name: RUN_HOVER;
		animation-timing-function: steps(16);
	}

	@media (min-width: 568px) {
		.newsletter-dialog__trigger-button__hover-animation[run] {
			animation-name: RUN_HOVER_FROM_XSMALL_UP;
		}
	}
}

@keyframes RUN_HOVER {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width) * -16);
	}
}

@keyframes RUN_HOVER_FROM_XSMALL_UP {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width-from-xsmall) * -16);
	}
}
/* #endregion */

/* #region Poof Animation. */
@media (hover: hover) {
	.newsletter-dialog__trigger-button__poof-animation {
		background-image: url("../images/pothole-poof-spritesheet.png");
		background-position: top left;
		background-repeat: no-repeat;
		background-size: calc(var(--pothole-width) * 6) 100%;
		display: none;
		pointer-events: none;
		position: absolute;
		bottom: 0;
		left: 0;
		width: var(--pothole-width);
		height: var(--pothole-width); /* Has 1:1 aspect ratio. */
	}

	@media (min-width: 568px) {
		.newsletter-dialog__trigger-button__poof-animation {
			background-size: calc(var(--pothole-width-from-xsmall) * 6) 100%;
			width: var(--pothole-width-from-xsmall);
			height: var(--pothole-width-from-xsmall); /* Has 1:1 aspect ratio. */
		}
	}

	.newsletter-dialog__trigger-button__poof-animation[run] {
		animation-duration: 250ms;
		animation-fill-mode: forwards;
		animation-iteration-count: 1;
		animation-name: RUN_POOF;
		animation-timing-function: steps(6);
		display: block;
	}

	@media (min-width: 568px) {
		.newsletter-dialog__trigger-button__poof-animation[run] {
			animation-name: RUN_POOF_FROM_XSMALL_UP;
		}
	}
}

@keyframes RUN_POOF {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width) * -6);
	}
}

@keyframes RUN_POOF_FROM_XSMALL_UP {
	from {
		background-position-x: 0;
	}

	to {
		background-position-x: calc(var(--pothole-width-from-xsmall) * -6);
	}
}
/* #endregion */
