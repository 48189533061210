.featured-boxes-v2 {
	display: grid;
	grid-row-gap: 30px;
	list-style: none;
	margin: 0;
	padding: 0;
}

/* mq-from-small */
@media (--mq-from-small) {
	.featured-boxes-v2 {
		grid-column-gap: 1.875rem;
		grid-template-columns: repeat(2, 1fr);
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.featured-boxes-v2 {
		grid-row-gap: 48px;
		grid-column-gap: 3rem;
	}
}

/* mq-from-wide */
@media (--mq-from-wide) {
	.featured-boxes-v2 {
		grid-template-columns: repeat(3, 1fr);
	}
}
