/*
 * FORM ELEMENTS
 * =============
 * Styling of seperate form elements
 */

/* Common form input styling */
.input,
.mc-field-group input[type="text"],
.mc-field-group input[type="email"],
.mc-field-group input[type="tel"],
.select,
.mc-field-group select,
.textarea,
.mc-field-group textarea {
	background-color: transparent;
	border-color: currentColor;
	border-style: solid;
	border-width: 2px;
	border-radius: 0;
	color: currentColor;
	font-family: var(--font-family);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
}
/* &:active,
	&:focus {
		border-color: var(--color-grey);
	} */
@media (--mq-from-medium) {
	.input,
	.mc-field-group input[type="text"],
	.mc-field-group input[type="email"],
	.mc-field-group input[type="tel"],
	.select,
	.mc-field-group select,
	.textarea,
	.mc-field-group textarea {
		font-size: 1.125rem;
	}
}

/* Label styling */
.label,
.mc-field-group label {
	color: currentColor;
	display: block;
	font-family: var(--font-family);
	font-size: 1rem;
	font-weight: var(--font-weight-regular);
}

.label:not(:last-child),
.mc-field-group label:not(:last-child) {
	margin-bottom: 0.625rem;
}

@media (--mq-from-medium) {
	.label:not(:last-child),
	.mc-field-group label:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

/* Text input styling */
.input,
.mc-field-group input[type="text"],
.mc-field-group input[type="email"],
.mc-field-group input[type="tel"],
.select,
.mc-field-group select {
	appearance: none;
	box-shadow: none;
	box-sizing: border-box;
	display: block;
	line-height: 2.875rem;
	outline: 0;
	padding-right: 1rem;
	padding-left: 1rem;
	width: 100%;
	height: 2.875rem;
}

/* Required indicator styling */
.indicates-required {
	font-size: 0.875rem;
	margin-bottom: 0.625rem;
	text-align: right;
}

#mc_embed_signup p {
	font-size: 0.875rem;
	margin-bottom: 0.625rem;
}

.clear::after {
	clear: both;
	content: "";
	display: table;
}

.clear .button {
	float: right;
}

/* Style Checkbox */
.checkbox__label,
.mc-field-group label.checkbox span {
	display: block;
	padding-left: 1.5rem;
	position: relative;
}

.checkbox__label::before,
.checkbox__label::after,
.mc-field-group label.checkbox span::before,
.mc-field-group label.checkbox span::after {
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
}

.checkbox__label::before,
.mc-field-group label.checkbox span::before {
	border: 2px solid currentColor;
	top: 4px;
	left: 0;
	width: 0.9rem;
	height: 0.9rem;
}

.checkbox__label::after,
.mc-field-group label.checkbox span::after {
	background-color: currentColor;
	top: 8px;
	left: 4px;
	transform: scale3d(0, 0, 1);
	transform-origin: left top;
	transition: transform var(--time-small) var(--ease-out-quad);
	width: 0.375rem;
	height: 0.375rem;
}

.checkbox__item:checked + .checkbox__label::after,
.mc-field-group label.checkbox input:checked + span::after {
	transform: scale3d(1, 1, 1);
}

.checkbox__item,
.mc-field-group label.checkbox input {
	display: none;
}

/* Reset fieldset markup */
.fieldset,
.mc_fieldset {
	border: 2px solid currentColor;
	padding: 1.5rem;
}

/*
 * FORM LAYOUTS
 * ============
 * Styling of form layouts
 */

.mc-field-group {
	margin-bottom: 1.5rem;
}

/*
 * MC MARKUP
 * =========
 * Selectors for Mailchimp specific stylings
 */

div[class^="content__gdpr"] {
	margin-bottom: 1.5rem;
}

div[class^="content__gdpr"] .mc-field-group {
	margin-bottom: 0.625rem;
}
