.footer-navigation-secondary {
	padding-bottom: 1.25rem;
}

@media (--mq-from-medium) {
	.footer-navigation-secondary {
		padding-bottom: 3.125rem;
	}
}

.footer-navigation-secondary__list {
	line-height: 1.375rem;
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: right;
}

@media (--mq-from-medium) {
	.footer-navigation-secondary__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
}

.footer-navigation-secondary__list__item {
	color: var(--color-stoplight);
}

.footer-navigation-secondary__list__item a {
	color: var(--color-stoplight);
}

@media (--mq-from-medium) {
	.footer-navigation-secondary__list__item:not(:last-child) {
		margin-right: 0.625rem;
	}
}
