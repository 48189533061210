.doormat-contact {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.doormat-contact__logo {
	color: var(--color-pitch);
	display: inline-block;
	align-self: flex-end;
}

.doormat-contact__logo .icon {
	display: block;
	stroke-miterlimit: 10;
	width: 78px;
	height: 18px;
}

@media (min-width: 1024px) {
	.doormat-contact__logo .icon {
		width: 99px;
		height: 23px;
	}
}

@media (min-width: 1380px) {
	.doormat-contact__logo .icon {
		width: 142px;
		height: 33px;
	}
}

.doormat-contact__logo.yust-logo--outline .icon {
	fill: none;
}

.doormat-contact__logo__slogan {
	display: block;
	font-size: 0.625rem;
	line-height: 1.5em;
	margin-top: 8px;
	margin-left: 65%;
	text-align: center;
	text-transform: uppercase;
	transform: translate(-50%, 0);
	width: 100%;
}

@media (min-width: 1024px) {
	.doormat-contact__logo__slogan {
		font-size: 0.75rem;
		width: 70%;
	}
}

@media (min-width: 1380px) {
	.doormat-contact__logo__slogan {
		font-size: 0.875rem;
		margin-top: 20px;
		width: 65%;
	}
}
