.button {
	appearance: none;
	background-color: var(--color-electric);
	border: none;
	box-sizing: border-box;
	color: white;
	cursor: pointer;
	display: inline-block;
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.39;
	padding: 8px 14px;
	text-decoration: none;
	transition: opacity 100ms;
}

@media (min-width: 768px) {
	.button {
		font-size: 1rem;
		padding: 10px 15px;
	}
}

@media (max-width: 1023px) {
	.button {
		margin-top: 4px;
	}
}

.button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.button:active {
	opacity: 0.8;
}

/* Sizes */

.button.button--large {
	font-size: 1rem;
	padding: 1.5625rem 3.75rem;
}

@media (min-width: 768px) {
	.button.button--large {
		font-size: 1.125rem;
		padding: 1.875rem 4.5rem;
	}
}

@media (max-width: 1023px) {
	.button.button--large {
		margin-top: 3rem;
		width: 100%;
	}
}

@media (min-width: 1380px) {
	.button.button--large {
		font-size: 1.5rem;
		padding: 2.5rem 6rem;
	}

	.button.button--large + .button--large {
		margin-left: 6rem;
	}
}

@media (max-width: 1023px) {
	.columns .button.button--large:only-child {
		margin-top: 0;
	}
}

/* Variations */

.button.button--text {
	background: none;
	color: var(--color-mercury);
	font-size: 1rem;
	line-height: 1;
	padding: 0;
	text-decoration: underline;
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.button.button--text {
		font-size: 1.125rem;
		padding: 0;
	}
}

@media (max-width: 1023px) {
	.button.button--text {
		margin-top: 3rem;
		width: 100%;
	}
}

@media (min-width: 1380px) {
	.button.button--text {
		font-size: 1.5rem;
	}

	.button.button--text + .button.button--text {
		margin-left: 6rem;
	}
}

.button.button--text:hover {
	text-decoration: none;
}

/* Background colors */

.button.button--bg-color-flamingo {
	background-color: var(--color-flamingo);
}

.button.button--bg-color-mercury {
	background-color: var(--color-mercury);
}

.button.button--bg-color-pitch {
	background-color: var(--color-pitch);
}

.button.button--bg-color-stoplight {
	background-color: var(--color-stoplight);
}

.button.button--bg-color-white {
	background-color: var(--color-white);
}

.button.button--bg-color-grey {
	background-color: var(--color-grey);
}

/* Text colors */

.button.button--text-color-electric {
	color: var(--color-electric);
}

.button.button--text-color-flamingo {
	color: var(--color-flamingo);
}

.button.button--text-color-mercury {
	color: var(--color-mercury);
}

.button.button--text-color-pitch {
	color: var(--color-pitch);
}

.button.button--text-color-stoplight {
	color: var(--color-stoplight);
}

.button.button--text-color-white {
	color: var(--color-white);
}
