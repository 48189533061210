.video-embed-player {
	background-color: var(--color-pitch);
	display: block;
	overflow: hidden;
	padding-top: calc(100% * (9 / 16));
	position: relative;
	width: 100%;
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player {
		padding-top: calc((100% * (9 / 16)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player {
		padding-top: calc((100% * (9 / 16)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player {
		padding-top: calc((100% * (9 / 16)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="1:1"] {
	padding-top: 100%;
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:1"] {
		padding-top: calc(100% + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:1"] {
		padding-top: calc(100% + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:1"] {
		padding-top: calc(100% + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="1:2"] {
	padding-top: 200%;
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:2"] {
		padding-top: calc(200% + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:2"] {
		padding-top: calc(200% + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="1:2"] {
		padding-top: calc(200% + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="2:1"] {
	padding-top: 50%;
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:1"] {
		padding-top: calc(50% + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:1"] {
		padding-top: calc(50% + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:1"] {
		padding-top: calc(50% + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="2:3"] {
	padding-top: calc(100% * (3 / 2));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:3"] {
		padding-top: calc((100% * (3 / 2)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:3"] {
		padding-top: calc((100% * (3 / 2)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="2:3"] {
		padding-top: calc((100% * (3 / 2)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="3:2"] {
	padding-top: calc(100% * (2 / 3));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:2"] {
		padding-top: calc((100% * (2 / 3)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:2"] {
		padding-top: calc((100% * (2 / 3)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:2"] {
		padding-top: calc((100% * (2 / 3)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="3:4"] {
	padding-top: calc(100% * (4 / 3));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:4"] {
		padding-top: calc((100% * (4 / 3)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:4"] {
		padding-top: calc((100% * (4 / 3)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="3:4"] {
		padding-top: calc((100% * (4 / 3)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="4:3"] {
	padding-top: calc(100% * (3 / 4));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="4:3"] {
		padding-top: calc((100% * (3 / 4)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="4:3"] {
		padding-top: calc((100% * (3 / 4)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="4:3"] {
		padding-top: calc((100% * (3 / 4)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="16:9"] {
	padding-top: calc(100% * (9 / 16));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="16:9"] {
		padding-top: calc((100% * (9 / 16)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="16:9"] {
		padding-top: calc((100% * (9 / 16)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="16:9"] {
		padding-top: calc((100% * (9 / 16)) + 3.75rem);
	}
}

.video-embed-player[data-aspect-ratio="21:9"] {
	padding-top: calc(100% * (9 / 21));
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="21:9"] {
		padding-top: calc((100% * (9 / 21)) + 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="21:9"] {
		padding-top: calc((100% * (9 / 21)) + 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player[data-aspect-ratio="21:9"] {
		padding-top: calc((100% * (9 / 21)) + 3.75rem);
	}
}

.video-embed-player__iframe {
	display: block;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-embed-player__iframe iframe,
.video-embed-player__iframe video {
	background-color: var(--color-pitch);
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (--mq-from-medium) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player__iframe {
		height: calc(100% - 3.5625rem);
	}
}

@media (min-width: 1280px) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player__iframe {
		height: calc(100% - 3.6875rem);
	}
}

@media (--mq-from-wide) {
	.action-panel__video-embed-player:not(:only-child) .video-embed-player__iframe {
		height: calc(100% - 3.75rem);
	}
}

.video-embed-player__poster,
.video-embed-player__teaser {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	transition:
		opacity 128ms linear,
		z-index 128ms linear;
	width: auto;
	height: 100%;
}

@supports (object-fit: cover) {
	.video-embed-player__poster,
	.video-embed-player__teaser {
		object-fit: cover;
		left: 0;
		transform: none;
		width: 100%;
	}
}

.video-embed-player__trigger:checked ~ .video-embed-player__poster,
.video-embed-player__trigger:checked ~ .video-embed-player__teaser {
	opacity: 0;
	transition:
		opacity 128ms linear,
		z-index 128ms linear 128ms;
	z-index: -1;
}

.video-embed-player__poster {
	z-index: 1;
}

.video-embed-player__teaser + .video-embed-player__poster {
	transition: none;
}

.video-embed-player__teaser {
	z-index: 2;
}

.video-embed-player__call-to-action {
	cursor: pointer;
	display: inline-block;
	margin-top: 0;
	margin-bottom: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 100%;
	z-index: 3;
}

.video-embed-player__trigger:checked ~ .video-embed-player__call-to-action {
	display: none;
}

.video-embed-player__call-to-action__label {
	display: inline-block;
	position: absolute;
	text-align: center;
	text-decoration: underline;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video-embed-player__call-to-action:focus .video-embed-player__call-to-action__label,
.video-embed-player__call-to-action:hover .video-embed-player__call-to-action__label {
	text-decoration: none;
}

.video-embed-player__call-to-action__play {
	border-color: transparent transparent transparent currentColor;
	border-style: solid;
	border-width: 25px 0 25px 44px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateZ(0);
	transition: transform 160ms ease-in-out;
	width: 0;
	height: 0;
}

.video-embed-player__call-to-action__play::before {
	background-color: currentColor;
	border-radius: 50%;
	content: "";
	opacity: 0.3;
	overflow: hidden;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-52px, -50%) scale3d(1.8, 1.8, 1) translateZ(0);
	width: 50px;
	height: 50px;
}

@media (--mq-from-medium) {
	.video-embed-player__call-to-action__play::before {
		transform: translate(-83px, -50%) scale3d(1.8, 1.8, 1) translateZ(0);
		width: 80px;
		height: 80px;
	}
}

@media (--mq-from-medium) {
	.video-embed-player__call-to-action__play {
		border-width: 40px 0 40px 70px;
	}
}

.video-embed-player__call-to-action:focus .video-embed-player__call-to-action__play,
.video-embed-player__call-to-action:hover .video-embed-player__call-to-action__play {
	transform: translate(-50%, -50%) scale3d(0.85, 0.85, 1);
}

.video-embed-player__toggle-mute {
	background: none;
	border: 0 solid transparent;
	border-radius: 0;
	box-shadow: 0 0 0 0 currentColor;
	display: none;
	outline: none;
	padding: 0;
	position: absolute;
	right: 1.25rem;
	bottom: 1.25rem;
	transform: translateY(-2px); /* box-shadow */
	transition: box-shadow 96ms linear;
	width: 1.875rem;
	height: 1.875rem;
	z-index: 4;
}

.video-embed-player__toggle-mute:focus,
.video-embed-player__toggle-mute:hover {
	box-shadow: 0 0 0 2px currentColor;
}

@media (--mq-from-medium) {
	.video-embed-player__toggle-mute {
		right: var(--space-medium);
		bottom: 3.625rem;
		width: 2.5rem;
		height: 2.5rem;
	}
}

@media (min-width: 1280px) {
	.video-embed-player__toggle-mute {
		bottom: 3.75rem;
	}
}

@media (--mq-from-wide) {
	.video-embed-player__toggle-mute {
		bottom: 3.8125rem;
	}
}

.video-embed-player__trigger:checked ~ .video-embed-player__toggle-mute {
	display: block;
}

.video-embed-player__toggle-mute .icon {
	width: 100%;
	height: 100%;
}

.video-embed-player__toggle-mute .icon-mute {
	display: none;
}

.is-unmuted .video-embed-player__toggle-mute .icon-mute {
	display: block;
}

.video-embed-player__toggle-mute .icon-unmute {
	display: block;
}

.is-unmuted .video-embed-player__toggle-mute .icon-unmute {
	display: none;
}
