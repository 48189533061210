.filter-small {
	background-color: var(--color-pitch);
	color: var(--color-white);
	padding-inline: 25px;
	padding-block: 1.5625rem;
}

/* from-medium,tablet-l,min-width-1024px */
@media (min-width: 64rem) {
	.filter-small {
		display: none;
	}
}

.filter-small__block {
	border-top: 1px solid var(--color-white);
	color: var(--color-white);
	padding-block: 0.9375rem;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.filter-small__block {
		padding-block: 1.25rem;
	}
}

.filter-small__block:last-child {
	border-bottom: 1px solid var(--color-white);
}

.filter-small__block__label {
	-mrh-resets: button;
	display: flex;
	font-size: 0.875rem; /* 14px */
	font-weight: 700;
	line-height: 1.125rem;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.filter-small__block__label {
		font-size: 1rem;
		line-height: 1.3125rem; /* 131.25% */
	}
}

.filter-small__block__label .icon {
	display: block;
	transform: rotate(90deg);
	transition: transform 96ms linear;
	width: 0.75rem;
	height: 0.75rem;
}

.filter-small__block__label[aria-expanded="true"] .icon {
	transform: scale(-1) rotate(90deg);
}

.filter-small__block__list {
	display: none;
	list-style: none;
	margin: 0;
	margin-top: 0.9375rem;
	padding: 0;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.filter-small__block__list {
		margin-top: 1.25rem;
	}
}

.filter-small__block__list__item:not(:last-child) {
	margin-bottom: 0.625rem;
}

.filter-small__block__list.mr-menu--expanded {
	display: block;
}
