.image {
	text-align: left;
}

.image__wrapper {
	position: relative;
	width: 100%;
}

.image__item {
	display: block;
	width: 100%;
}

.image__caption {
	font-size: 0.75rem;
	padding-top: 1.25rem;
}

@media (--mq-from-small) {
	.image__caption {
		font-size: 0.875rem;
	}
}

.image--full-width .image__caption {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (--mq-from-medium) {
	.image--full-width .image__caption {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}
