.aligned-cta {
	position: relative;
	height: 100%;
}

.aligned-cta__text {
	padding-top: 1.25rem;
	padding-right: 1.25rem;
	padding-bottom: 3.75rem;
	padding-left: 1.25rem;
}

@media (--mq-from-medium) {
	.aligned-cta__text {
		padding-bottom: 5.5rem;
	}
}

.aligned-cta__cta {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

.aligned-cta__cta .button {
	display: block;
	text-align: center;
	width: 100%;
}
