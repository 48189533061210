.doormat-instagram-link {
	background-color: var(--color-electric);
	color: var(--color-white);
	font-weight: var(--font-weight-bold);
	padding: 1.25rem;
	justify-content: center;
	text-align: center;
}

.doormat-instagram-link__image {
	margin-right: auto;
	margin-bottom: 1.625rem;
	margin-left: auto;
	width: 100%;
	height: auto;
}
