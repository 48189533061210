.sticky-bar {
	background-color: var(--color-pitch);
	color: var(--color-white);
	padding: 1.25rem;
	position: sticky;
	text-align: center;
	z-index: 10;
}

@media (--mq-from-small) {
	.sticky-bar {
		padding: 2rem 1.25rem;
	}
}

@media (--mq-up-to-medium) {
	.sticky-bar {
		top: 61px; /* Height of site-header up to breakpoint medium */
		transition: top var(--time-medium) var(--ease-in-quad);
	}
}

@media (--mq-from-medium) {
	.sticky-bar {
		top: 51px; /* Height of site-header from breakpoint medium */
	}
}

@media (--mq-from-wide) {
	.sticky-bar {
		top: 66px; /* Height of site-header from breakpoint wide */
	}
}
