.video {
	text-align: left;
}

.video__wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;
}

.video__item {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	height: 100%;
}

@supports (object-fit: cover) {
	.video__item {
		object-fit: cover;
		width: 100%;
	}
}

.video__caption {
	font-size: 0.875rem;
	padding-top: 1.25rem;
}

.video--full-width .video__caption {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (--mq-from-medium) {
	.video--full-width .video__caption {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

.video__poster-image {
	opacity: 1;
	transition: opacity var(--time-x-small) var(--ease-out-quad);
	width: 100%;
	height: auto;
}

.is-playing .video__poster-image {
	opacity: 0;
}
