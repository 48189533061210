.privacy-control {
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.privacy-control__inner {
		padding-right: calc((100% - 30rem) / 2);
		padding-left: calc((100% - 30rem) / 2);
	}
}

.privacy-control__section {
}

@media (--mq-from-small) {
	.privacy-control__section {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}
}

@media (--mq-from-medium) {
	.privacy-control__section {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

.privacy-control__section:not(:last-child) {
	margin-bottom: 3rem;
}

.privacy-control__columns {
}

@media (--mq-from-medium) {
	.privacy-control__columns {
		display: flex;
	}
}

.privacy-control__columns:not(:last-child) {
	margin-bottom: 1.5rem;
}

.privacy-control__column {
	margin-bottom: 1rem;
}

@media (--mq-from-small) {
	.privacy-control__column {
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
	}
}

.privacy-control__column .form__radio {
	position: relative;
}

.privacy-control__column .form__radio:not(:last-child) {
	margin-bottom: 0.625rem;
}

@media (--mq-from-medium) {
	.privacy-control__column .form__radio:not(:last-child) {
		margin-bottom: 1.5rem;
	}
}

.privacy-control__column .form__radio__label::before,
.privacy-control__column .form__radio__label::after {
	box-sizing: border-box;
	content: "";
	display: block;
	position: absolute;
}

.privacy-control__column .form__radio__label::before {
	border: 2px solid currentColor;
	top: 4px;
	left: 0;
	width: 0.9rem;
	height: 0.9rem;
}

.privacy-control__column .form__radio__label::after {
	background-color: currentColor;
	top: 8px;
	left: 4px;
	transform: scale3d(0, 0, 1);
	transform-origin: left top;
	transition: transform var(--time-small) var(--ease-out-quad);
	width: 0.375rem;
	height: 0.375rem;
}

.privacy-control__column .form__radio__input {
	opacity: 0;
}

.privacy-control__column .form__radio__input:checked + .form__radio__label::after {
	transform: scale3d(1, 1, 1);
}

.privacy-control__column .form__radio__label {
	margin-left: 0.5rem;
}
