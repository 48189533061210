@media (--mq-up-to-xsmall) {
	.grid {
		padding-bottom: var(--space-x-small);
	}
}

@media (--mq-from-xsmall) {
	.grid {
		display: flex;
		flex-wrap: wrap;
		margin-right: -1rem;
		margin-left: -1rem;
	}
}

@media (--mq-from-medium) {
	.grid {
		margin-right: calc(var(--space-x-small) * -1);
		margin-left: calc(var(--space-x-small) * -1);
	}
}

.grid__item {
	margin-bottom: var(--space-x-small);
}

@media (--mq-from-xsmall) {
	.grid__item {
		box-sizing: border-box;
		flex-basis: 50%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-right: 1rem;
		padding-left: 1rem;
		max-width: 50%;
	}
}

@media (--mq-from-small) {
	.grid__item {
		flex-basis: 33.3333%;
		margin-bottom: var(--space-small);
		max-width: 33.3333%;
	}
}

@media (--mq-from-medium) {
	.grid__item {
		margin-bottom: var(--space-medium);
		padding-right: var(--space-x-small);
		padding-left: var(--space-x-small);
	}
}

@media (min-width: 80em) {
	.grid__item {
		flex-basis: 25%;
		max-width: 25%;
	}
}

@media (--mq-from-wide) {
	.grid__item {
		margin-bottom: 3.125rem;
	}
}

@media (--mq-up-to-xsmall) {
	.grid__item:last-child {
		margin-bottom: 0;
	}
}
