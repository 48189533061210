.booking-form {
	font-size: 1rem;
}

@media (min-width: 80em) {
	.booking-form {
		font-size: 1.125rem;
	}
}

.booking-form__form {
	background-color: black;
	box-sizing: border-box;
	color: white;
	padding: 20px 15px;
}

@media (--mq-from-small) {
	.booking-form__form {
		display: flex;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.booking-form__form {
		flex-wrap: wrap;
	}
}

@media (--mq-from-small) {
	.booking-form__form__item {
		box-sizing: border-box;
		flex-basis: 45%;
		flex-grow: 1;
		flex-shrink: 0;
		padding-right: 5px;
		padding-left: 5px;
	}
}

@media (--mq-from-medium) {
	.booking-form__form__item {
		flex-basis: 30%;
	}
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.booking-form__form__item:first-child,
	.booking-form__form__item:nth-child(2) {
		margin-bottom: 1rem;
	}
}

@media (--mq-up-to-small) {
	.booking-form__form__item:not(:last-child) {
		margin-bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.booking-form__form__item.booking-form__form__item--submit {
		display: flex;
		align-items: flex-end;
	}
}

.booking-form__form__label {
	display: inline-block;
	margin-bottom: 0.75rem;
}

.booking-form__form__label {
	display: inline-block;
	margin-bottom: 0.75rem;
}

.booking-form__form__field {
	appearance: none;
	background: white;
	border-color: white;
	border-style: solid;
	border-width: 1px;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: var(--color-electric);
	display: block;
	line-height: 2.75rem;
	outline: 0;
	padding-right: 10px;
	padding-left: 10px;
	transition: border-color 96ms linear;
	width: 100%;
	height: 2.75rem;
}

.booking-form__form__field:focus {
	border-color: var(--color-electric);
}

.booking-form__form__submit {
	appearance: none;
	background: var(--color-electric);
	border: 0;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	color: white;
	display: block;
	line-height: 2.75rem;
	outline: 0;
	padding-right: 10px;
	padding-left: 10px;
	text-transform: uppercase;
	transition: color 96ms linear;
	width: 100%;
	height: 2.75rem;
}

.booking-form__form__submit:hover,
.booking-form__form__submit:focus {
	color: var(--color-stoplight);
}

.booking-form__form__submit:hover {
	cursor: pointer;
}

.booking-form__confirmation {
	background-color: var(--color-mercury);
	box-sizing: border-box;
	color: black;
	margin-top: 10px;
	padding: 20px;
}
