.pagination {
	display: flex;
	list-style: none;
	margin-bottom: 0;
	padding-bottom: 1.25rem;
	padding-left: 0;
	justify-content: space-between;
}

@media (--mq-from-medium) {
	.pagination {
		padding-bottom: var(--space-medium);
	}
}

.pagination__item:only-child {
	width: 100%;
}

.pagination__item.pagination__item--previous {
	white-space: nowrap;
}

.pagination__item.pagination__item--next {
	text-align: right;
	white-space: nowrap;
}

.pagination__link {
	display: inline-block;
}

.pagination__item--previous .pagination__link::before {
	content: " \1F448";
	display: inline-block;
	padding-right: 0.625rem;
}

.pagination__item--next .pagination__link::after {
	content: " \1F449";
	display: inline-block;
	padding-left: 0.625rem;
}
