.language-menu {
	position: relative;
}

.language-menu__checkbox {
	display: none;
}

.language-menu__label {
	cursor: pointer;
	position: relative;
}

.language-menu__label .icon {
	margin-left: 4px;
	transform: rotate(90deg);
	transition: transform 256ms ease-out;
	width: 0.7rem;
	height: 0.7rem;
}

.language-menu__checkbox:checked ~ .language-menu__label .icon {
	transform: rotate(-90deg);
}

.language-menu__list {
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: calc(100% + 8px);
	left: 50%;
	transform: translateX(-50%);
	width: 220px;
	z-index: 100;
}

.language-menu__checkbox:checked ~ .language-menu__list {
	opacity: 1;
	pointer-events: auto;
}

.language-menu__list:focus {
	opacity: 1;
	pointer-events: auto;
}

.language-menu__list:focus-within {
	opacity: 1;
	pointer-events: auto;
}
