.outlines {
	color: var(--color-mercury);
	font-size: 1.5rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
	text-shadow:
		-1px -1px 0 var(--color-pitch),
		1px -1px 0 var(--color-pitch),
		-1px 1px 0 var(--color-pitch),
		1px 1px 0 var(--color-pitch);
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.outlines {
		font-size: 1.75rem;
	}
}

@media (--mq-from-medium) {
	.outlines {
		font-size: 2.25rem;
	}
}

@media (--mq-from-wide) {
	.outlines {
		font-size: 3rem;
	}
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines {
		-webkit-text-stroke: 1px var(--color-pitch);
		text-shadow: none;
	}
}

.outlines a {
	color: var(--color-pitch);
	text-decoration: none;
}

.outlines a:hover {
	text-decoration: underline;
}

.outlines strong {
	color: var(--color-pitch);
}

/* Theme modifiers: BG */

.outlines.outlines--bg-electric {
	color: var(--color-electric);
}

.outlines.outlines--bg-flamingo {
	color: var(--color-flamingo);
}

.outlines.outlines--bg-mercury {
	color: var(--color-mercury);
}

.outlines.outlines--bg-pitch {
	color: var(--color-pitch);
}

.outlines.outlines--bg-stoplight {
	color: var(--color-stoplight);
}

.outlines.outlines--bg-white {
	color: var(--color-white);
}

/* Theme modifiers: color */

.outlines.outlines--color-electric {
	text-shadow:
		-1px -1px 0 var(--color-electric),
		1px -1px 0 var(--color-electric),
		-1px 1px 0 var(--color-electric),
		1px 1px 0 var(--color-electric);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-electric {
		-webkit-text-stroke: 1px var(--color-electric);
		text-shadow: none;
	}
}

.outlines.outlines--color-electric a,
.outlines.outlines--color-electric strong {
	color: var(--color-electric);
}

.outlines.outlines--color-flamingo {
	text-shadow:
		-1px -1px 0 var(--color-flamingo),
		1px -1px 0 var(--color-flamingo),
		-1px 1px 0 var(--color-flamingo),
		1px 1px 0 var(--color-flamingo);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-flamingo {
		-webkit-text-stroke: 1px var(--color-flamingo);
		text-shadow: none;
	}
}

.outlines.outlines--color-flamingo a,
.outlines.outlines--color-flamingo strong {
	color: var(--color-flamingo);
}

.outlines.outlines--color-mercury {
	text-shadow:
		-1px -1px 0 var(--color-mercury),
		1px -1px 0 var(--color-mercury),
		-1px 1px 0 var(--color-mercury),
		1px 1px 0 var(--color-mercury);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-mercury {
		-webkit-text-stroke: 1px var(--color-mercury);
		text-shadow: none;
	}
}

.outlines.outlines--color-mercury a,
.outlines.outlines--color-mercury strong {
	color: var(--color-mercury);
}

.outlines.outlines--color-pitch {
	text-shadow:
		-1px -1px 0 var(--color-pitch),
		1px -1px 0 var(--color-pitch),
		-1px 1px 0 var(--color-pitch),
		1px 1px 0 var(--color-pitch);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-pitch {
		-webkit-text-stroke: 1px var(--color-pitch);
		text-shadow: none;
	}
}

.outlines.outlines--color-pitch a,
.outlines.outlines--color-pitch strong {
	color: var(--color-pitch);
}

.outlines.outlines--color-stoplight {
	text-shadow:
		-1px -1px 0 var(--color-stoplight),
		1px -1px 0 var(--color-stoplight),
		-1px 1px 0 var(--color-stoplight),
		1px 1px 0 var(--color-stoplight);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-stoplight {
		-webkit-text-stroke: 1px var(--color-stoplight);
		text-shadow: none;
	}
}

.outlines.outlines--color-stoplight a,
.outlines.outlines--color-stoplight strong {
	color: var(--color-stoplight);
}

.outlines.outlines--color-white {
	text-shadow:
		-1px -1px 0 var(--color-white),
		1px -1px 0 var(--color-white),
		-1px 1px 0 var(--color-white),
		1px 1px 0 var(--color-white);
}

@supports (-webkit-text-stroke: 1px black) {
	.outlines.outlines--color-white {
		-webkit-text-stroke: 1px var(--color-white);
		text-shadow: none;
	}
}

.outlines.outlines--color-white a,
.outlines.outlines--color-white strong {
	color: var(--color-white);
}

.outlines--justify {
	text-align: justify;
}

.outlines--full-width {
	padding-right: 1.25rem;
	padding-left: 1.25rem;
}

@media (--mq-from-medium) {
	.outlines--full-width {
		padding-right: var(--space-medium);
		padding-left: var(--space-medium);
	}
}

.outlines--small_text,
.outlines--text {
	font-size: 2.25rem;
}

@media (--mq-from-small) {
	.outlines--small_text,
	.outlines--text {
		font-size: 2.25rem;
	}
}

.outlines--word-break {
	word-break: break-all;
}
