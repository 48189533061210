@keyframes marquee {
	0% {
		transform: translate(0, 0);
	}

	100% {
		transform: translate(-50%, 0);
	}
}

@supports (transform: translate(calc(-50% + 0.625rem), 0)) {
	@keyframes marquee {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(calc(-50% + 0.625rem), 0);
		}
	}
}

.footer-keywords {
	font-size: 0.75rem;
	letter-spacing: 2px;
	line-height: 2em;
	overflow: hidden;
	text-align: justify;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.footer-keywords {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	.footer-keywords {
		font-size: 1.125rem;
	}
}

.footer-keywords__keywords {
}

.footer-keywords__keywords__line {
	display: inline-block;
	white-space: nowrap;
}

.is-animating .footer-keywords__keywords__line {
	animation-iteration-count: infinite;
	animation-name: marquee;
	animation-timing-function: linear;
}

.is-animating .footer-keywords__keywords__line:nth-child(odd) {
	animation-direction: reverse;
}

.footer-keywords__keywords__line-wrapper {
	box-sizing: border-box;
	display: inline-block;
}

.footer-keywords__keywords__line-wrapper:last-child {
	padding-right: 1.25rem;
}

.footer-keywords__highlight {
	color: var(--color-stoplight);
}

.footer-keywords__fill-line {
	display: block;
	position: relative;
	white-space: nowrap;
}

.footer-keywords__fill-line::before {
	background: linear-gradient(to left, var(--color-white), transparent);
	content: "";
	position: absolute;
	top: 0;
	right: -0.5px;
	bottom: 0;
	width: 1.5rem;
}

.footer-keywords__fill-line::after {
	content: ".yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust.yust";
}
