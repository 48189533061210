.service-icons {
	display: block;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	position: relative;
}

.service-icons__slide {
	display: flex;
	list-style: none;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
}

@media (--mq-up-to-small) {
	.service-icons__slide {
		flex-wrap: wrap;
	}
}

@media (--mq-from-xsmall) {
	.service-icons__slide {
		padding-right: 3rem;
		padding-left: 3rem;
	}
}

@media (--mq-from-small) {
	.service-icons__slide {
		padding-right: 5rem;
		padding-left: 5rem;
	}
}

@media (min-width: 120em) {
	.service-icons__slide {
		padding-right: calc((100% - 98rem) / 2);
		padding-left: calc((100% - 98rem) / 2);
	}
}

.service-icons.is-resolved .service-icons__slide {
	display: none;
	margin-bottom: 0;
}

.service-icons.is-resolved .service-icons__slide.is-active {
	display: flex;
}

.service-icons__item {
	flex-basis: 12.5%;
	text-align: center;
}

@media (--mq-up-to-small) {
	.service-icons__item {
		flex-basis: 25%;
	}

	.service-icons__item:nth-child(n + 5) {
		margin-top: 1.25rem;
	}
}

.service-icons__link,
.service-icons__img {
	display: inline-block;
	width: 2.5rem;
	height: 2.5rem;
}

@media (--mq-from-xsmall) {
	.service-icons__link,
	.service-icons__img {
		width: 3.125rem;
		height: 3.125rem;
	}
}

.service-icons__previous,
.service-icons__next {
	display: none;
	position: absolute;
	top: 50%;
	width: 16px;
	height: 20px;
}

.service-icons.is-resolved .service-icons__previous,
.service-icons.is-resolved .service-icons__next {
	display: block;
}

.service-icons__previous .icon,
.service-icons__next .icon {
	color: var(--color-stoplight);
	display: block;
	width: 16px;
	height: 20px;
}

.service-icons__previous {
	left: 0;
	transform: translate(-50%, -50%);
}

@media (--mq-from-small) {
	.service-icons__previous {
		left: 24px;
	}
}

@media (--mq-from-small) {
	.service-icons__previous {
		left: 40px;
	}
}

.service-icons__next {
	right: 0;
	transform: translate(50%, -50%);
}

@media (--mq-from-small) {
	.service-icons__next {
		right: 24px;
	}
}

@media (--mq-from-small) {
	.service-icons__next {
		right: 40px;
	}
}
