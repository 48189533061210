/* stylelint-disable @mrhenry/stylelint-mrhenry-attribute-selector-no-unknown */
html {
	scroll-behavior: smooth;
}

body {
	overflow: hidden;
	overflow-y: auto;
}

@media (max-width: 1023px) {
	body {
		margin-top: 61px;
	}
}

/* Popup & Book now */
body[has-open-overlay] {
	overflow: hidden;
}

/* Menu on Mobile */
@media (max-width: 1023px) {
	body[has-open-menu] {
		overflow: hidden;
	}
}

/* Add default a styling */
a {
	color: var(--color-pitch);
	text-decoration: underline;
}

a:hover {
	text-decoration: none;
}

/* Add default margin bottom to all text elements */
address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
hr,
table,
fieldset {
	margin-top: 0;
	margin-bottom: 0;
}

address:not(:last-child),
h1:not(:last-child),
h2:not(:last-child),
h3:not(:last-child),
h4:not(:last-child),
h5:not(:last-child),
h6:not(:last-child),
blockquote:not(:last-child),
p:not(:last-child),
pre:not(:last-child),
dl:not(:last-child),
ol:not(:last-child),
ul:not(:last-child),
hr:not(:last-child),
table:not(:last-child),
fieldset:not(:last-child) {
	margin-bottom: calc(var(--space-x-small) * 0.75);
}

@media (min-width: 768px) {
	address:not(:last-child),
	h1:not(:last-child),
	h2:not(:last-child),
	h3:not(:last-child),
	h4:not(:last-child),
	h5:not(:last-child),
	h6:not(:last-child),
	blockquote:not(:last-child),
	p:not(:last-child),
	pre:not(:last-child),
	dl:not(:last-child),
	ol:not(:last-child),
	ul:not(:last-child),
	hr:not(:last-child),
	table:not(:last-child),
	fieldset:not(:last-child) {
		margin-bottom: var(--space-x-small);
	}
}

ol:not([class]),
ul:not([class]) {
	list-style: none;
	margin-top: 0;
	margin-right: 0;
	margin-bottom: 1rem;
	margin-left: 0;
	padding-left: 1.25rem;
}

@media (min-width: 768px) {
	ol:not([class]),
	ul:not([class]) {
		padding-left: 1.75rem;
	}
}

li ol:not([class]),
li ul:not([class]) {
	margin-bottom: 0;
}

ol:not([class]) {
	counter-reset: ol-list-items;
}

ol:not([class]) ol:not([class]) {
	counter-reset: ol-sub-list-items;
	padding-left: 2rem;
}

@media (min-width: 768px) {
	ol:not([class]) ol:not([class]) {
		padding-left: 3rem;
	}
}

li:not([class]) {
	line-height: 1.5;
	margin: 0;
	padding: 0;
	position: relative;
}

li:not([class]):last-child {
	margin: 0;
}

li:not([class])::before {
	position: absolute;
}

ul:not([class]) li:not([class])::before {
	background-color: currentColor;
	border-radius: 0.25rem;
	content: "";
	display: block;
	top: 0.5rem;
	left: -0.875rem;
	width: 0.25rem;
	height: 0.25rem;
}

@media (min-width: 768px) {
	ul:not([class]) li:not([class])::before {
		border-radius: 0.5rem;
		top: 0.55rem;
		left: -1.125rem;
		width: 0.5rem;
		height: 0.5rem;
	}
}

ol:not([class]) li:not([class])::before {
	color: black;
	content: counter(ol-list-items);
	counter-increment: ol-list-items;
	font-weight: var(--font-weight-regular);
	top: 0;
	left: -1.25rem;
}

@media (min-width: 768px) {
	ol:not([class]) li:not([class])::before {
		left: -1.75rem;
	}
}

ol:not([class]) ol:not([class]) li:not([class])::before {
	content: counter(ol-list-items) "." counter(ol-sub-list-items);
	counter-increment: ol-sub-list-items;
	left: -2rem;
}

@media (min-width: 768px) {
	ol:not([class]) ol:not([class]) li:not([class])::before {
		left: -3rem;
	}
}

img {
	display: block;
	max-width: 100%;
}

table,
th,
td {
	border: 1px solid black;
}

table {
	border-collapse: collapse;
	margin-top: var(--space-x-small);
	margin-right: 0;
	margin-bottom: var(--space-x-small);
	margin-left: 0;
	width: 100%;
	max-width: 100%;
}

@media (min-width: 768px) {
	table {
		margin-top: var(--space-small);
		margin-bottom: var(--space-small);
	}
}

table:first-child {
	margin-top: 0;
}

table:last-child {
	margin-bottom: 0;
}

th,
td {
	padding: 1rem;
	text-align: left;
}

@media (min-width: 768px) {
	th,
	td {
		padding: 1rem 1.875rem;
	}
}

th {
	font-weight: var(--font-weight-bold);
}

td {
	font-weight: var(--font-weight-regular);
}

dl {
	margin: 0 0 2rem 0;
}

dl:last-child {
	margin-bottom: 0;
}

dt {
	font-weight: var(--font-weight-bold);
}

dd {
	margin: 0;
	padding: 1rem 0;
}

hr {
	background-color: var(--color-pitch);
	border: none;
	height: 1px;
}

figure {
	margin: 0;
}
