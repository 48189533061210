.booking-cta-mobile {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 99;
}

@media (min-width: 568px) {
	.booking-cta-mobile {
		right: auto;
		bottom: 62px;
		left: 58px;
	}
}

@media (min-width: 1024px) {
	.booking-cta-mobile {
		display: none;
	}
}
