.overlay {
	box-sizing: border-box;
	display: block;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
}

@media (--mq-up-to-small) {
	.overlay {
		background-color: var(--color-mercury);
		overflow-x: hidden;
		overflow-y: auto;
		padding: 1.25rem;
		height: 100vh;
	}
}

@media (--mq-from-small) {
	.overlay {
		height: 100vh;
	}
}

.l-styleguide .overlay {
	position: absolute;
}

@media (--mq-from-small) {
	.l-styleguide .overlay {
		overflow: hidden;
	}
}

.overlay.overlay--no-overlay {
	background-color: var(--color-mercury);
	overflow: visible;
	padding-top: 1.25rem;
	padding-bottom: 1.25rem;
	position: relative;
	height: auto;
}

@media (--mq-from-small) {
	.overlay__closer {
		background-color: rgb(0 0 0 / 0.7);
		cursor: pointer;
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		z-index: 10;
	}

	.overlay__closer span {
		display: none;
	}

	.l-styleguide .overlay__closer {
		position: absolute;
	}
}

.overlay__close-button {
	color: var(--color-stoplight);
	display: block;
	font-size: 2rem;
	font-weight: lighter;
	position: absolute;
	top: 1rem;
	right: 1rem;
	transition: background-color 96ms linear;
	width: 2rem;
	height: 2rem;
	z-index: 4;
}

.overlay__close-button::after {
	content: "×";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.overlay__close-button:hover {
	background-color: var(--color-flamingo);
	cursor: pointer;
}

@media (--mq-from-small) {
	.overlay__wrapper {
		background-color: var(--color-mercury);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		margin: 1.25rem;
		overflow-x: hidden;
		overflow-y: auto;
		padding: 1.25rem;
		justify-content: space-between;
		position: relative;
		height: calc(100% - 2.5rem);
		z-index: 20;
	}
}

@media (--mq-from-medium) {
	.overlay__wrapper {
		margin: var(--space-medium);
		padding: var(--space-medium);
		height: calc(100% - (2 * var(--space-medium)));
	}
}

@media (min-width: 90em) {
	.overlay__wrapper {
		margin-right: calc((100% - 84rem) / 2);
		margin-left: calc((100% - 84rem) / 2);
	}
}
