.l-error {
	background-color: var(--color-mercury);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	justify-content: center;
	min-height: 100vh;
}

.l-error__logo {
	/* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
	font-family: "Sneak Mono";
	margin-bottom: 1.25rem;
	text-align: right;
}

@media (--mq-from-medium) {
	.l-error__logo {
		margin-bottom: var(--space-medium);
	}
}

.l-error__text {
	text-align: center;
}
