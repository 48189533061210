.box-v2 {
}

.box-v2__link {
	color: var(--color-black);
	text-decoration: none;
	transition: color 96ms linear;
}

.box-v2__link:focus,
.box-v2__link:hover {
	color: var(--color-electric);
}

.box-v2__header {
	background-color: var(--color-flamingo);
	padding: 25px;
}

.box-v2__header__data {
	padding-left: 10px;
}

.box-v2__header__wrapper {
	display: flex;
	margin-bottom: 6px;
	justify-content: space-between;
	align-items: flex-start;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.box-v2__header__wrapper {
		margin-bottom: 10px;
	}
}

@media (--mq-from-wide) {
	.box-v2__header__wrapper {
		margin-bottom: 15px;
	}
}

.box-v2__title {
	font-weight: var(--font-weight-bold);
	text-transform: uppercase;
}

.box-v2__data.outlines {
	display: block;
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.13;
	text-transform: lowercase;
}

@media (--mq-from-xsmall) {
	.box-v2__data.outlines {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.box-v2__data.outlines {
		font-size: 1.75rem;
	}
}

.box-v2__header__taxonomies {
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.25;
	margin-bottom: 0.9375rem;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.box-v2__header__taxonomies {
		font-size: 1rem;
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.box-v2__header__taxonomies {
		font-size: 1.125rem;
		margin-bottom: 1.5625rem;
	}
}

@media (--mq-from-xsmall) {
	.box-v2__header__description {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	.box-v2__header__description {
		font-size: 1.125rem;
	}
}

.box-v2__content {
	display: flex;
	align-items: stretch;
}

.box-v2__content__image-wrapper {
	width: 100%;
}

.box-v2__content__image {
	width: 100%;
	height: auto;
}

.box-v2__content__cta {
	display: flex;
	flex-direction: column;
	width: 38px;
}

.box-v2__cta-text-wrapper {
	background-color: var(--color-flamingo);
	flex-grow: 1;
	position: relative;
}

.box-v2__cta-text {
	font-size: 0.875rem;
	opacity: 0;
	position: absolute;
	text-transform: uppercase;
	bottom: 25px;
	left: 50%;
	transform: translateY(50%) rotate(-90deg) translateZ(0);
	transform-origin: left center;
	transition:
		transform var(--time-medium) var(--ease-out-quad),
		opacity var(--time-small) linear var(--time-x-small);
}

.box-v2__link:focus .box-v2__cta-text,
.box-v2__link:hover .box-v2__cta-text {
	opacity: 1;
	transform: rotate(-90deg) translateZ(0);
}

.box-v2__cta-triangle-wrapper {
	position: relative;
	width: 38px;
	height: 38px;
}

.box-v2__cta-triangle-wrapper svg {
	color: currentColor;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 0.75rem;
	height: 0.75rem;
}

.box-v2__cta {
	display: flex;
}

.box-v2__cta__pink-box {
	background-color: var(--color-flamingo);
	width: 100%;
}
