.filter-large {
	background-color: var(--color-pitch);
	color: var(--color-white);
	display: none;
	padding-block: 1.6875rem;
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.filter-large {
		display: block;
	}
}

/* mq-from-medium */
@media (--mq-from-medium) {
	.filter-large__inner {
		display: flex;
		justify-content: space-between;
	}
}

.filter-large__block {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
}

.filter-large[data-columns="2"] .filter-large__block {
	flex-basis: calc(50% - 48px);
}

.filter-large[data-columns="3"] .filter-large__block {
	flex-basis: calc(33.33% - 48px);
}

.filter-large__block__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.filter-large__block__list__item {
	display: inline-block;
	margin-block: 0.3125rem;
}

.filter-large__block__list__item:not(:last-child) {
	margin-right: 20px;
}
