.doormat-menus {
	background-color: var(--color-flamingo);
	color: var(--color-stoplight);
	flex-direction: row;
	font-size: 0.875rem;
	text-align: center;
}

.doormat-menus > div:not(:last-child) {
	border-right: 3px dashed var(--color-white);
}

@media (min-width: 1024px) {
	.doormat-menus > div:not(:last-child) {
		border-right: none;
		border-bottom: 3px dashed var(--color-white);
	}
}

.doormat-menus > div {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
	justify-content: center;
	align-items: center;
}

@media (min-width: 1024px) {
	.doormat-menus {
		flex-direction: column;
	}
}

.doormat-menus__language-switch {
	flex-basis: 37%;
}

.doormat-menus__meta {
	flex-basis: 46%;
}

.doormat-menus__social {
	flex-basis: 17%;
}

@media (min-width: 1024px) {
	.doormat-menus__language-switch,
	.doormat-menus__social {
		flex-basis: 27%;
	}
}

.doormat-menus__meta__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

@media (min-width: 1024px) {
	.doormat-menus__meta__list__item:not(:last-child) {
		margin-bottom: 3px;
	}
}

@media (min-width: 1380px) {
	.doormat-menus__meta__list__item:not(:last-child) {
		margin-bottom: 5px;
	}
}

.doormat-menus__meta__list__link {
	color: var(--color-stoplight);
	position: relative;
	text-decoration: none;
}

.doormat-menus__meta__list__link:hover::before,
.doormat-menus__meta__list__link:focus::before,
.doormat-menus__meta__list__link.is-active::before {
	border-bottom: 1px solid var(--color-stoplight);
	content: "";
	display: block;
	position: absolute;
	right: 0;
	bottom: 1px;
	left: 0;
}

.doormat-menus__meta__list__link.menu-item--has-star::after {
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA0MCI+PGcgZmlsbD0iI2YwMjgyOCI+PHBvbHlnb24gcG9pbnRzPSIyMCAwIDIzLjgzIDEwLjc2IDM0LjE0IDUuODYgMjkuMjQgMTYuMTcgNDAgMjAgMjkuMjQgMjMuODMgMzQuMTQgMzQuMTQgMjMuODMgMjkuMjQgMjAgNDAgMTYuMTcgMjkuMjQgNS44NiAzNC4xNCAxMC43NiAyMy44MyAwIDIwIDEwLjc2IDE2LjE3IDUuODYgNS44NiAxNi4xNyAxMC43NiAyMCAwIi8+PC9nPjwvc3ZnPg==");
	display: inline-block;
	margin-left: 3px;
	vertical-align: text-top;
	width: 0.75rem;
	height: 0.75rem;
}

.doormat-menus__social__list {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
}

@media (min-width: 1024px) {
	.doormat-menus__social__list {
		flex-direction: row;
	}
}

.doormat-menus__social__list__item {
	margin: 0.45rem 0;
}

.doormat-menus__social__list__item .icon {
	display: block;
	width: 1rem;
	height: 1rem;
}

@media (min-width: 1024px) {
	.doormat-menus__social__list__item .icon {
		width: 1.25rem;
		height: 1.25rem;
	}
}

@media (min-width: 1024px) {
	.doormat-menus__social__list__item {
		margin: 0 0.625rem;
	}
}

.doormat-menus__social__list__link {
	border: none;
	color: currentColor;
	text-decoration: none;
}

.doormat-menus__social__list__label:not(:only-child) {
	display: none;
}
