.image-gallery {
	display: block;
	width: 100%;
}

.image-gallery__image-wrapper {
	border: 1px solid var(--color-stoplight);
	box-sizing: border-box;
	display: none;
	overflow: hidden;
	padding-top: 77.59%;
	position: relative;
	width: 100%;
	height: auto;
}

.image-gallery__image-wrapper:only-child,
.image-toggle:checked + .image-gallery__image-wrapper {
	display: block;
	margin-bottom: 1.25rem;
}

.overlay .image-gallery__image-wrapper {
	border: 1px solid var(--color-flamingo);
}

.image-gallery__image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) translateZ(0);
	width: auto;
	max-width: none;
	height: 100%;
}

@supports (object-fit: contain) {
	.image-gallery__image {
		object-fit: contain;
		width: 100%;
		height: 100%;
	}
}

.image-gallery__thumbnail-wrapper {
	cursor: pointer;
	display: inline-block;
	margin-right: 0.625rem;
	margin-bottom: 0.625rem;
}

.image-toggle:nth-of-type(1):checked ~ .image-gallery__thumbnail-wrapper.is-image1,
.image-toggle:nth-of-type(2):checked ~ .image-gallery__thumbnail-wrapper.is-image2,
.image-toggle:nth-of-type(3):checked ~ .image-gallery__thumbnail-wrapper.is-image3,
.image-toggle:nth-of-type(4):checked ~ .image-gallery__thumbnail-wrapper.is-image4,
.image-toggle:nth-of-type(5):checked ~ .image-gallery__thumbnail-wrapper.is-image5,
.image-toggle:nth-of-type(6):checked ~ .image-gallery__thumbnail-wrapper.is-image6,
.image-toggle:nth-of-type(7):checked ~ .image-gallery__thumbnail-wrapper.is-image7,
.image-toggle:nth-of-type(8):checked ~ .image-gallery__thumbnail-wrapper.is-image8,
.image-toggle:nth-of-type(9):checked ~ .image-gallery__thumbnail-wrapper.is-image9,
.image-toggle:nth-of-type(10):checked ~ .image-gallery__thumbnail-wrapper.is-image10 {
	display: none;
}
