.post-teaser {
	color: black;
	display: block;
	font-family: var(--font-family);
	font-size: 0.875rem;
	line-height: 1.29;
	padding-top: calc(100% + 1.25rem);
	position: relative;
	text-decoration: none;
}

@media (--mq-from-small) {
	.post-teaser {
		font-size: 1rem;
	}
}

.post-teaser p {
	margin-bottom: 0;
}

.post-teaser:hover {
	text-decoration: underline;
}

.post-teaser::before {
	background-color: var(--color-white);
	content: "";
	padding-top: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.post-teaser__thumbnail {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.post-teaser__mask {
	overflow: hidden;
	padding-top: 100%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.post-teaser__mask::before,
.post-teaser__mask .icon-past-event {
	position: absolute;
}

.post-teaser__mask::before {
	background-color: var(--color-pitch);
	content: "";
	opacity: 0.3;
	top: 0;
	left: 0;
	transition: opacity var(--time-small) var(--ease-out-quad);
	width: 100%;
	height: 100%;
}

.post-teaser__mask .icon-past-event {
	fill: var(--color-flamingo);
	fill-opacity: 0;
	stroke: var(--color-white);
	stroke-opacity: 1;
	top: -1px;
	left: -1px;
	transition:
		fill-opacity var(--time-small) var(--ease-out-quad),
		stroke-opacity var(--time-small) var(--ease-out-quad);
	width: calc(100% + 2px);
	height: calc(100% + 2px);
}

.post-teaser:focus .post-teaser__mask::before,
.post-teaser:hover .post-teaser__mask::before {
	opacity: 0;
}

.post-teaser:focus .post-teaser__mask .icon-past-event,
.post-teaser:hover .post-teaser__mask .icon-past-event {
	fill-opacity: 1;
	stroke-opacity: 0;
}

.post-teaser__meta {
	display: flex;
}

.post-teaser__meta__info {
	box-sizing: border-box;
	flex-basis: 65%;
	flex-grow: 1;
	flex-shrink: 1;
	font-weight: var(--font-weight-bold);
	margin-bottom: 1.25rem;
	padding-right: 0.25rem;
}

.post-teaser__meta__datetime {
	flex-basis: auto;
	flex-grow: 1;
	flex-shrink: 1;
	text-align: right;
}

.post-teaser__title {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	margin-bottom: 0 !important;
}

.post-teaser__type {
	text-transform: uppercase;
}
