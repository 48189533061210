body {
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	color: var(--color-pitch);
	font-family: var(--font-family);
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.29;
}

@media (--mq-from-small) {
	body {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	body {
		font-size: 1.125rem;
		line-height: 1.39;
	}
}

/* pick something visible over all theme colors */
*::selection {
	background-color: #000000;
	color: #e6e6e6;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: var(--font-family);
	margin-top: 0.875rem;
	margin-bottom: 0.875rem;
}

@media (--mq-from-small) {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
.h1:first-child,
.h2:first-child,
.h3:first-child,
.h4:first-child,
.h5:first-child,
.h6:first-child {
	margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child {
	margin-bottom: 0;
}

h1:only-child,
h2:only-child,
h3:only-child,
h4:only-child,
h5:only-child,
h6:only-child,
.h1:only-child,
.h2:only-child,
.h3:only-child,
.h4:only-child,
.h5:only-child,
.h6:only-child {
	margin-top: 0;
	margin-bottom: 0;
}

h1,
.h1 {
	font-size: 1.875rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
}

@media (--mq-from-small) {
	h1,
	.h1 {
		font-size: 2.25rem;
	}
}

@media (--mq-from-wide) {
	h1,
	.h1 {
		font-size: 3rem;
	}
}

h2,
.h2 {
	font-size: 1.5rem;
	font-weight: var(--font-weight-regular);
	line-height: normal;
}

@media (--mq-from-small) {
	h2,
	.h2 {
		font-size: 1.75rem;
	}
}

@media (--mq-from-wide) {
	h2,
	.h2 {
		font-size: 2.25rem;
	}
}

h3,
.h3 {
	font-size: 1.25rem;
	font-weight: var(--font-weight-bold);
	line-height: 1.13;
}

@media (--mq-from-small) {
	h3,
	.h3 {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	h3,
	.h3 {
		font-size: 2rem;
	}
}

h4,
.h4 {
	font-size: 1rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
}

@media (--mq-from-small) {
	h4,
	.h4 {
		font-size: 1.125rem;
	}
}

@media (--mq-from-wide) {
	h4,
	.h4 {
		font-size: 1.5rem;
	}
}

h5 .h5 {
	font-size: 0.875rem;
	font-weight: var(--font-weight-regular);
	line-height: 1.39;
}

@media (--mq-from-small) {
	h5 .h5 {
		font-size: 1rem;
	}
}

@media (--mq-from-wide) {
	h5 .h5 {
		font-size: 1.25rem;
	}
}

h6,
.h6 {
	font-size: 0.875rem;
	font-weight: var(--font-weight-bold);
	line-height: normal;
}

@media (--mq-from-wide) {
	h6,
	.h6 {
		font-size: 1.125rem;
		line-height: 1.5;
	}
}

a:not([class]) {
	color: currentColor;
	text-decoration: underline;
}

a:not([class]):hover {
	text-decoration: none;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

i,
em,
blockquote {
	font-style: italic;
}

/* wysiwyg modifier classes */
.large-text {
	font-size: 1rem;
	line-height: 1.29;
}

@media (--mq-from-small) {
	.large-text {
		font-size: 1.125rem;
		line-height: 1.39;
	}
}

@media (--mq-from-wide) {
	.large-text {
		font-size: 1.5rem;
		line-height: 1.5;
	}
}
